import React from 'react';

export const DiaryThanksPage: React.FC = () => {
  return (
    <div className="thanks-page__container">
      <p className="form__title thanks-page__title">
        Thank you <br />
        for submission
      </p>
    </div>
  );
};
