import { all, takeEvery } from 'redux-saga/effects';
import { communicationPatientSessions } from 'entities/PatientSessions/PatientSessions.communication';

function* errorWatcher() {
  yield takeEvery('*', function logger(action: any) {
    if (action.type.match('FAIL')) {
      console.log('ERROR', action.payload);
    }
  });
}

export default function* rootSaga(): any {
  yield all([errorWatcher(), ...communicationPatientSessions.sagas]);
}
