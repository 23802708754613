/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import { Form, FormInstance, Table, Tooltip } from 'antd';
import { FormInput } from 'common/components/Form/FormInput';
import { IFormValues, IPatientAnswer, IQuestionnaireData } from 'common/models/formBuilder.models';
import { ManageBtns } from 'common/components/Form/ManageBtns';
import { FormSelect } from 'common/components/Form/FormSelect';
import { symptomsListOptions } from 'common/const/questionnaire.const';
import { IQuestionnaireElement, IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  name: string;
  data: IQuestionnaireData[];
  onSubmit: (values: IFormValues) => void;
  handleSave: (comment: string) => void;
  handleChange: (values: IFormValues, formController: FormInstance<IFormValues>) => void;
  patientAnswers?: IPatientAnswer[];
}

export const PretherapySymptompsList: React.FC<IComponentProps> = (props) => {
  const { name, onSubmit, handleSave, questionnaire, patientAnswers, handleChange } = props;
  const [tableData, setTableData] = useState<IQuestionnaireData[] | []>([]);
  const [formController] = Form.useForm<IFormValues>();

  useEffect(() => {
    if (questionnaire.elements) {
      const data: IQuestionnaireData[] = questionnaire.elements
        .slice(1, questionnaire.elements.length)
        .map((element: IQuestionnaireElement, index: number) => ({
          key: index,
          medication: element.question,
          item: element.textId,
        }));
      setTableData(data);

      // If any fields have been pre-filled, writes its values to the answers object
      const prefilledAnswers: IFormValues = {};
      questionnaire.elements.forEach((element: IQuestionnaireElement) => {
        prefilledAnswers[element.textId] = element.patientAnswers;
      });
      handleChange(prefilledAnswers, formController);
      // ===========================================================================
    }
  }, [questionnaire.elements]);

  const columns = [
    {
      title: 'Symptom',
      dataIndex: 'medication',
    },
    {
      title: () => (
        <div className="tooltip-container">
          <span>Severity right now</span>
          <Tooltip
            className="tooltip"
            title="Please enter the severity of your symptoms right now to give us a baseline for today."
          />
        </div>
      ),
      render: (key: IQuestionnaireData) => (
        <FormSelect
          key={key.key}
          name={[key.item, `${key.item}_severity_right_now`]}
          placeholder="Severity"
          options={symptomsListOptions}
        />
      ),
    },
    {
      title: () => (
        <div className="tooltip-container">
          <span>Typical severity at bedtime</span>
          <Tooltip
            className="tooltip"
            title="Please enter the severity you would expect for your symptoms at bedtime to help us see if the treatment is helping your symptoms."
          />
        </div>
      ),
      render: (key: IQuestionnaireData) => (
        <FormSelect
          key={key.key}
          name={[key.item, `${key.item}_typical_severity_at_bedtime`]}
          placeholder="Severity"
          options={symptomsListOptions}
        />
      ),
    },
  ];

  const handleOnValuesChanged = (_: any, values: IFormValues) => {
    handleChange(values, formController);
  };

  return (
    <Form onFinish={onSubmit} fields={patientAnswers} form={formController} onValuesChange={handleOnValuesChanged}>
      <h1 className="form__title">{name}</h1>
      <p className="form__description">Please describe how you expect symptoms severity to change throughout the day</p>
      <FormInput
        name={questionnaire.elements[0].textId}
        placeholder={questionnaire.elements[0].placeholder}
        validators={questionnaire.elements[0].validators}
        type="text"
      />
      <Table columns={columns} dataSource={tableData} pagination={false} />
      <ManageBtns handleSave={handleSave} />
    </Form>
  );
};
