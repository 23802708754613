import React from 'react';
import { Checkbox, Row, Col } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { body, ECommonBodyParts } from 'common/const/bodyAreas.const';
import { ReactComponent as Head } from 'app/assets/images/svg/bodyAreas/head.svg';
import { ReactComponent as RightArm } from 'app/assets/images/svg/bodyAreas/rightArm.svg';
import { ReactComponent as LeftArm } from 'app/assets/images/svg/bodyAreas/leftArm.svg';
import { ReactComponent as RightHand } from 'app/assets/images/svg/bodyAreas/rightHand.svg';
import { ReactComponent as LeftHand } from 'app/assets/images/svg/bodyAreas/leftHand.svg';
import { ReactComponent as Chest } from 'app/assets/images/svg/bodyAreas/chest.svg';
import { ReactComponent as Legs } from 'app/assets/images/svg/bodyAreas/legs.svg';
import { ReactComponent as Feet } from 'app/assets/images/svg/bodyAreas/feet.svg';

interface IComponentProps {
  onChange?: (checkedValue: CheckboxValueType[]) => void;
}

export const BodyAreasCheckboxGroup: React.FC<IComponentProps> = (props) => {
  const { onChange } = props;

  return (
    <>
      <h2>
        List of <br />
        body areas
      </h2>
      <Checkbox.Group className="body_areas__checkbox_group" onChange={onChange}>
        <Checkbox value={body[ECommonBodyParts.HeadNeck].value}>
          <Head />
          {body[ECommonBodyParts.HeadNeck].title}
        </Checkbox>
        <Row gutter={14}>
          <Col span={12}>
            <Checkbox value={body[ECommonBodyParts.RightArm].value}>
              <RightArm />
              {body[ECommonBodyParts.RightArm].title}
            </Checkbox>
          </Col>
          <Col span={12}>
            <Checkbox value={body[ECommonBodyParts.LeftArm].value}>
              <LeftArm />
              {body[ECommonBodyParts.LeftArm].title}
            </Checkbox>
          </Col>
        </Row>
        <Row gutter={14}>
          <Col span={12}>
            <Checkbox value={body[ECommonBodyParts.RightHand].value}>
              <RightHand />
              {body[ECommonBodyParts.RightHand].title}
            </Checkbox>
          </Col>
          <Col span={12}>
            <Checkbox value={body[ECommonBodyParts.LeftHand].value}>
              <LeftHand />
              {body[ECommonBodyParts.LeftHand].title}
            </Checkbox>
          </Col>
        </Row>
        <Checkbox value={body[ECommonBodyParts.ChestAbdomenBack].value}>
          <Chest />
          {body[ECommonBodyParts.ChestAbdomenBack].title}
        </Checkbox>
        <Checkbox value={body[ECommonBodyParts.Legs].value}>
          <Legs />
          {body[ECommonBodyParts.Legs].title}
        </Checkbox>
        <Checkbox value={body[ECommonBodyParts.Feet].value}>
          <Feet />
          {body[ECommonBodyParts.Feet].title}
        </Checkbox>
      </Checkbox.Group>
    </>
  );
};
