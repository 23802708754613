import React from 'react';

export const PretherapyFinishPage: React.FC = () => {
  return (
    <div className="pretherapy-text-page__container">
      <p className="form__title pretherapy-text-page__title">
        Thank you for entering your pre-therapy information.
        <br />
        Please contact your coordinator to start your first treatment.
        <br />
        Afterwards, you will begin receiving text messages with links to your daily diary
      </p>
    </div>
  );
};
