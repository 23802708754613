/* eslint-disable sonarjs/no-small-switch */
export const generateFormItemClassName = (textId: string, itemType: string): string => {
  switch (true) {
    case !!textId.startsWith('baseline_msq_version_two_one'):
    case !!textId.startsWith('diary_pgic'):
    case !!textId.startsWith('diary_msq_version_two_one'):
      return `form__${itemType}--block`;
    default:
      return `form__${itemType}`;
  }
};
