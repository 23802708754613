import React, { useEffect, useRef } from 'react';
import { Form, Radio, Tooltip } from 'antd';
import { baseValidationRules } from 'common/const/questionnaire.const';

interface IComponentProps {
  name: string;
  label?: string;
  radioButtons: string[];
  className?: string;
  tooltip?: string;
}

export const FormRadio: React.FC<IComponentProps> = (props) => {
  const { name, label, radioButtons, className, tooltip } = props;
  const labelRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (label && labelRef.current) {
      labelRef.current.innerHTML = label;
    }
  }, [label]);

  return (
    <Form.Item
      label={
        <>
          {label && <span ref={labelRef}>{label}</span>}
          {tooltip && <Tooltip className="tooltip" title={tooltip} />}
        </>
      }
      name={name}
      rules={baseValidationRules()}
    >
      <Radio.Group className={className}>
        {radioButtons?.map((item, index) => {
          return (
            <Radio key={index} value={item}>
              {item}
            </Radio>
          );
        })}
      </Radio.Group>
    </Form.Item>
  );
};
