import React, { useMemo, useState } from 'react';
import { Form, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import axios from 'axios';
import { baseValidationRules } from 'common/const/questionnaire.const';
import { useDebounce } from 'common/hooks/useDebounce';

interface IComponentProps {
  label?: string;
  className?: string;
  name?: string | string[];
  initialValue?: string;
  placeholder?: string;
  rules?: Rule[];
  disabled?: boolean;
  preserve?: boolean;
}

export const FormLookup: React.FC<IComponentProps> = (props) => {
  const { label, className, name, initialValue = '', placeholder = 'Search to Select', rules, disabled, preserve } = props;
  const defaultResults = useMemo(() => 'I cannot find my zipcode', []);
  const [options, setOptions] = useState<string[]>([defaultResults]);
  const [searchString, setSearchString] = useState<string>(initialValue);

  const getOptions = async (searchString: string | undefined) => {
    if (searchString === undefined) {
      setOptions([defaultResults]);

      return;
    }

    const clearedSearchString = searchString.trim();

    if (clearedSearchString === '') {
      setOptions([defaultResults]);

      return;
    }

    if (isNaN(Number(clearedSearchString))) {
      setOptions([defaultResults]);

      return;
    }

    try {
      // TODO Implement a helper for the url according to the domain field
      //
      // {
      //   "textId": "diary_demographics_14",
      //   "question": "ZIP code:",
      //   "type": "lookup",
      //   "domain": "zip_code"
      // },
      const response: { data: { code: string }[] } = await axios.get(
        `${window.location.origin}/api/zip-codes?limit=100&code=${clearedSearchString}`
      );

      const foundCodes = response.data.map((result) => result.code);

      setOptions([...foundCodes, defaultResults]);
    } catch (error) {
      setOptions([]);
    }
  };

  useDebounce(
    () => {
      getOptions(searchString);
    },
    300,
    [searchString]
  );

  return (
    <Form.Item
      className={className}
      label={label}
      name={name}
      initialValue={initialValue}
      rules={!disabled ? rules || baseValidationRules() : undefined}
      preserve={preserve}
    >
      <Select
        showSearch
        allowClear={true}
        filterOption={false}
        value={searchString}
        placeholder={placeholder}
        disabled={disabled}
        onSearch={setSearchString}
        options={options.map((option) => ({ label: option, value: option }))}
        notFoundContent={null}
      />
    </Form.Item>
  );
};
