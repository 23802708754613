import { BaseHttpTransport } from '@axmit/transport';
import axios, { AxiosInstance } from 'axios';
import { ICopyAnswersRequest } from 'common/models/formBuilder.models';
import { IImageModel, IQuestionnaireModel, IQuestionnareCreateParams } from 'entities/PatientSessions/PatientSession.models';

const basePath = 'patient-sessions';
const patientQuestionnairesPath = 'patient-questionnaires';
const imageUploadPath = 'body-map';

export const patientSessionsTransport = new BaseHttpTransport<AxiosInstance, any>(`${basePath}/next`, axios);
export const patientSessionsQuestionnareTransport = new BaseHttpTransport<AxiosInstance, IQuestionnaireModel>(basePath, axios);
export const patientSessionsPrevQuestionnareTransport = new BaseHttpTransport<AxiosInstance, IQuestionnaireModel>(
  `${basePath}/prev`,
  axios
);
export const patientSessionsPartialQuestionnareTransport = (
  params: IQuestionnareCreateParams
): Promise<IQuestionnareCreateParams> => axios.put(`${basePath}/next`, params);
export const patientSessionsCopyAnswers = (params: ICopyAnswersRequest): Promise<IQuestionnaireModel> =>
  axios.get(`${patientQuestionnairesPath}/answers/copies/${params.id}?textId=${params.textId}`);
export const patientSessionsUploadImage = (
  studyPatientId: number,
  sessionId: string,
  formData: FormData
): Promise<IImageModel> => {
  const imagePath = encodeURIComponent(`${imageUploadPath}/${studyPatientId.toString()}/`);

  return axios.post(`images/${imagePath}?sessionId=${sessionId}`, formData);
};
