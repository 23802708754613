import React from 'react';

interface IComponentProps {
  active?: boolean;
}

export const Badge: React.FC<IComponentProps> = (props) => {
  const { children, active = false } = props;
  return (
    <div className="badge" style={active ? { backgroundColor: 'rgba(67, 94, 145, 0.82)', color: 'white', opacity: 0.82 } : {}}>
      {children}
    </div>
  );
};
