export const enum EHeadNeck {
  'Head/Neck (Front)' = 'Head/Neck (Front)',
  'Head/Neck (Back)' = 'Head/Neck (Back)',
  'Head/Neck (Right)' = 'Head/Neck (Right)',
  'Head/Neck (Left)' = 'Head/Neck (Left)',
  'Head/Neck (Top)' = 'Head/Neck (Top)',
}

export const enum ERightArm {
  'Right Arm (Front)' = 'Right Arm (Front)',
  'Right Arm (Back)' = 'Right Arm (Back)',
}

export const enum ELeftArm {
  'Left Arm (Front)' = 'Left Arm (Front)',
  'Left Arm (Back)' = 'Left Arm (Back)',
}

export const enum ERightHand {
  'Right Hand (Front)' = 'Right Hand (Front)',
  'Right Hand (Back)' = 'Right Hand (Back)',
}

export const enum ELeftHand {
  'Left Hand (Front)' = 'Left Hand (Front)',
  'Left Hand (Back)' = 'Left Hand (Back)',
}

export const enum EChestAbdomenBack {
  'Chest/Abdomen' = 'Chest/Abdomen',
  'Back' = 'Back',
  'Left' = 'Left',
  'Right' = 'Right',
}

export const enum ELegs {
  'Legs (Front)' = 'Legs (Front)',
  'Legs (Back)' = 'Legs (Back)',
  'Left Leg' = 'Left Leg',
  'Right Leg' = 'Right Leg',
}

export const enum EFeet {
  'Feet (Front)' = 'Feet (Front)',
  'Feet (Back)' = 'Feet (Back)',
}
