import React from 'react';
import { Button, Form } from 'antd';

interface IComponentProps {
  className?: string;
  onClick?: () => void;
  type?: 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed';
  htmlType?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  size?: 'default' | 'large';
}

export const FormButton: React.FC<IComponentProps> = (props) => {
  const { className, onClick, type, htmlType, children, disabled, size } = props;

  return (
    <Form.Item className={className} style={{ maxWidth: size === 'large' ? '276px' : '103px' }}>
      <Button type={type} htmlType={htmlType} onClick={onClick} disabled={disabled}>
        {children}
      </Button>
    </Form.Item>
  );
};
