import React, { useState } from 'react';
import { ReactComponent as ZoomInIcon } from 'app/assets/images/svg/zoomIn.svg';
import DVPRSscale from 'app/assets/images/DVPRSscale.png';

export const PopupMobile: React.FC = () => {
  const [imageZoomedIn, setImageZoomedIn] = useState<boolean>(false);

  return (
    <div className={imageZoomedIn ? 'pain_levels--zoomed mt-8' : 'pain_levels mt-8'}>
      <div className="pain_levels__tooltip">
        <ZoomInIcon />
        <span className="pain_levels__tooltip_text">Tap to zoom in</span>
      </div>
      <div>
        <img
          crossOrigin="anonymous"
          className="pain_levels__image mt-8"
          src={DVPRSscale}
          alt="Pain levels"
          onClick={() => setImageZoomedIn(!imageZoomedIn)}
        />
      </div>
    </div>
  );
};
