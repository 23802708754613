import { useEffect, useState } from 'react';

type ReturnValues = [number, number, () => void];

export const useTimer = (limit: number): ReturnValues => {
  const [timer, setTimer] = useState<number>(1);
  // Turn ms into s
  const timerLimit = limit / 1000;

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((timer) => (timer + 1 > timerLimit ? 1 : timer + 1));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const flush = () => setTimer(timerLimit);

  return [timer, timerLimit, flush];
};
