import { ECommonBodyParts } from 'common/const/bodyAreas.const';
import {
  EChestAbdomenBack,
  EFeet,
  EHeadNeck,
  ELeftArm,
  ELeftHand,
  ELegs,
  ERightArm,
  ERightHand,
} from 'common/const/painAreas/bodyParts.enums';

export const painAreasProjections: { [key: string]: string[] } = {
  [ECommonBodyParts.HeadNeck]: [
    EHeadNeck['Head/Neck (Front)'],
    EHeadNeck['Head/Neck (Back)'],
    EHeadNeck['Head/Neck (Right)'],
    EHeadNeck['Head/Neck (Left)'],
    EHeadNeck['Head/Neck (Top)'],
  ],
  [ECommonBodyParts.RightArm]: [ERightArm['Right Arm (Front)'], ERightArm['Right Arm (Back)']],
  [ECommonBodyParts.LeftArm]: [ELeftArm['Left Arm (Front)'], ELeftArm['Left Arm (Back)']],
  [ECommonBodyParts.RightHand]: [ERightHand['Right Hand (Front)'], ERightHand['Right Hand (Back)']],
  [ECommonBodyParts.LeftHand]: [ELeftHand['Left Hand (Front)'], ELeftHand['Left Hand (Back)']],
  [ECommonBodyParts.ChestAbdomenBack]: [
    EChestAbdomenBack['Chest/Abdomen'],
    EChestAbdomenBack['Back'],
    EChestAbdomenBack['Left'],
    EChestAbdomenBack['Right'],
  ],
  [ECommonBodyParts.Legs]: [ELegs['Legs (Front)'], ELegs['Legs (Back)'], ELegs['Left Leg'], ELegs['Right Leg']],
  [ECommonBodyParts.Feet]: [EFeet['Feet (Front)'], EFeet['Feet (Back)']],
};
