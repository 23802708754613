import React from 'react';
import { Form, Select } from 'antd';
import { baseValidationRules } from 'common/const/questionnaire.const';

interface IComponentProps {
  label?: string;
  options: string[];
  name: string;
  answers: string[];
  placeholder?: string;
}

export const FormSelectGroup: React.FC<IComponentProps> = (props) => {
  const { label, options, name, answers, placeholder } = props;

  return (
    <>
      <span className="form__select__group__header">{label}</span>
      {answers.map((answer: string) => (
        <Form.Item
          className="form__select__group__item"
          key={answer}
          label={answer}
          // name equals [textId, answer]. Examples: ['baseline_head_31', 'Fatigue'] or ['baseline_head_38', 'Nausea / Vomiting']
          name={[name, answer]}
          rules={baseValidationRules()}
        >
          <Select placeholder={placeholder} options={options.map((option) => ({ value: option, label: option }))} />
        </Form.Item>
      ))}
    </>
  );
};
