import React, { Dispatch, SetStateAction } from 'react';
import { Button, Form, Modal, Row } from 'antd';
import { Moment } from 'moment';
import { IFormValues } from 'common/models/formBuilder.models';
import { FormInput } from 'common/components/Form/FormInput';
import { FormTimePicker } from 'common/components/Form/FormTimePicker';
import { FormSelect } from 'common/components/Form/FormSelect';
import { numericFieldsValidators, tempFieldValidators } from 'common/const/questionnaire.const';
import { IQuestionnaireElement } from 'entities/PatientSessions/PatientSession.models';

interface ITreatmentSummaryProps {
  modalItem: IQuestionnaireElement;
  setModalItem: (item: IQuestionnaireElement | null) => void;
  setAnswers: Dispatch<SetStateAction<IFormValues[]>>;
  answers: IFormValues[];
}

export const TreatmentSummaryModal: React.FC<ITreatmentSummaryProps> = (props) => {
  const { modalItem, setModalItem, setAnswers, answers } = props;

  const name = modalItem.textId;
  const footer = (
    <div className="dts__treatment_summary__modal__footer">
      <Button type="primary" htmlType="submit" form="form">
        Save
      </Button>
      <Button onClick={() => setModalItem(null)}>Cancel</Button>
    </div>
  );

  const handleFinish = (values: IFormValues) => {
    // Object.keys(entry)[0] represents the textId value
    const existingItem = answers.find((entry: IFormValues) => Object.keys(entry)[0] === Object.keys(values)[0]);

    const key = Object.keys(values)[0];
    const timeRangeObjectKey = `${key}_time_range`;

    Object.values(values)[0][timeRangeObjectKey] = Object.values(values)[0][timeRangeObjectKey].map((value: Moment) =>
      value.format()
    );

    // If item was found in answers - then we add another treatment cycle to it
    if (existingItem) {
      existingItem[Object.keys(existingItem)[0]].push(Object.values(values)[0]);
      setAnswers([...answers]);
      // Create new record instead
    } else {
      setAnswers([...answers, { [Object.keys(values)[0]]: [Object.values(values)[0]] }]);
    }
    setModalItem(null);
  };

  return (
    <Modal className="dts__treatment_summary__modal" width="100%" footer={footer} closable={false} centered visible>
      <h1>{modalItem.question}</h1>
      <div className="dts__treatment_summary__modal__values">
        <Form id="form" onFinish={handleFinish}>
          <FormInput name={[name, `${name}_temp`]} type="number" placeholder="Temp (℉)" validators={tempFieldValidators} />
          <FormTimePicker className="dts__treatment_summary__modal__values__time_range" name={[name, `${name}_time_range`]} />
          <FormSelect name={[name, `${name}_gel`]} placeholder="Gel" options={['Yes', 'No']} />
          <Row className="dts__treatment_summary__modal__values__pain_level" justify="space-between">
            <FormInput
              name={[name, `${name}_pain_level_start`]}
              type="number"
              placeholder="Pain level Start"
              validators={numericFieldsValidators}
            />
            <FormInput
              name={[name, `${name}_pain_level_end`]}
              type="number"
              placeholder="Pain level End"
              validators={numericFieldsValidators}
            />
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
