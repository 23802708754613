import React, { useEffect, useRef } from 'react';
import { Form, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { baseValidationRules } from 'common/const/questionnaire.const';

const { Option } = Select;

interface IComponentProps {
  name?: string;
  label?: string;
  options: string[];
  value?: string;
  className?: string;
  handleChange?: (value?: SelectValue) => void;
  placeholder?: string;
}

export const FormSelectSingle: React.FC<IComponentProps> = (props) => {
  const { name, label, options, value, className, placeholder, handleChange } = props;
  const labelRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (label && labelRef.current) {
      labelRef.current.innerHTML = label;
    }
  }, [label]);

  return (
    <Form.Item
      className={className}
      name={name}
      label={label && <span ref={labelRef}>{label}</span>}
      initialValue={value}
      rules={baseValidationRules()}
    >
      <Select defaultValue={value} placeholder={placeholder} onChange={handleChange}>
        {options?.map((item, index) => {
          return (
            <Option key={index} value={item}>
              {item}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};
