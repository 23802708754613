import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Row } from 'antd';
import { Rule } from 'antd/lib/form';

interface IComponentProps {
  className?: string;
  onCancel: () => void;
  onSave: (comment: string) => void;
  value?: string;
  visible: boolean;
}

export const AdditionalComments: React.FC<IComponentProps> = (props) => {
  const { value = '', onCancel, onSave, visible } = props;
  const [comment, setComment] = useState<string>(value);

  const { TextArea } = Input;
  const formValidationRules: Rule[] = [
    { type: 'string', max: 512, message: 'Your answer should be no longer than 512 characters' },
  ];

  useEffect(() => {
    setComment(value);
  }, [value]);

  return (
    <Modal visible={visible} footer={null} centered closable={false} title="Additional comments">
      <Form onFinish={() => onSave(comment)} fields={[{ name: 'comments', value: comment }]}>
        <Form.Item name="comments" rules={formValidationRules}>
          <TextArea
            className="mb-4"
            value={comment}
            rows={8}
            placeholder="Please add your additional comments here"
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value)}
          />
        </Form.Item>
        <Row className="form__comments__control_buttons mt-6 mb-6">
          <Button onClick={onCancel}>Cancel</Button>
          <Button htmlType="submit" type="primary" className="ml-4" disabled={!comment}>
            Save
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
