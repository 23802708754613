import React from 'react';
import { DatePicker, Form } from 'antd';
import { baseValidationRules } from 'common/const/questionnaire.const';

interface IComponentProps {
  name: string;
  label: string;
}

export const FormDatePicker: React.FC<IComponentProps> = (props) => {
  const { name, label } = props;
  return (
    <Form.Item label={label} name={name} rules={baseValidationRules()}>
      <DatePicker
        placeholder={label}
        format="ll"
        disabledDate={(currentDate) => currentDate && currentDate.valueOf() > Date.now()}
      />
    </Form.Item>
  );
};
