import React from 'react';

interface IComponentProps {
  loading: boolean;
  errors: boolean | null;
  success?: boolean;
  message?: string;
}

export const FormStatus: React.FC<IComponentProps> = (props) => {
  const { loading, errors, success, message } = props;

  const basicMessage = 'Saving failed. Please check your connection and update the form before closing the window';

  const renderStatus = () => {
    if (loading) {
      return <div className="form__status">Saving...</div>;
    }

    if (errors) {
      return <div className="form__status form__status--error">{message || basicMessage}</div>;
    }

    if (success) {
      return <div className="form__status">Data successfully saved</div>;
    }

    return null;
  };

  return <div>{renderStatus()}</div>;
};
