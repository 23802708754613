import React from 'react';
import { Button } from 'antd';
import { IBaseParams } from 'common/models/formBuilder.models';
import { IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  handleSubmit: (params: IBaseParams) => void;
}

export const ThanksPage: React.FC<IComponentProps> = (props) => {
  const { questionnaire, handleSubmit } = props;

  const onSubmit = () => {
    handleSubmit({ elements: [] });
  };

  return (
    <div className="thanks-page__container">
      <p className="form__title thanks-page__title">Thank you for participating in our study</p>
      <p className="thanks-page__tip">
        We&apos;ve now collected your baseline forms and you&apos;re ready to begin your baseline diary. For the next 28 days
        you&apos;ll keep track of your
        <br /> pain, symptoms, and medications taken. We will also work with you to collect your past year&apos;s healthcare
        spending. We&apos;re asking that
        <br /> you keep a stable course of preventative medication throughout the baseline and first three months of the trial.
      </p>
      <p className="form__description thanks-page__description">
        Please keep up with your daily diaries and contact us anytime with questions or for help: 484-679-6274 or
        <a href="mailto:info@thermaquil.com"> info@thermaquil.com</a>
      </p>
      {questionnaire.session.isApprovedByPatient ? (
        <p className="thanks-page__approved_text">
          Thank you for completing your background forms.
          <br />
          You will receive a link to your baseline diary soon.
        </p>
      ) : (
        <Button className="thanks-page__btn" type="primary" onClick={onSubmit}>
          Procced to baseline diary
        </Button>
      )}
    </div>
  );
};
