import React, { useEffect, useState } from 'react';
import { Form, Table, Tooltip } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';
import { FormInput } from 'common/components/Form/FormInput';
import { IFormValues, IPatientAnswer, IQuestionnaireData } from 'common/models/formBuilder.models';
import { ManageBtns } from 'common/components/Form/ManageBtns';
import { bedTimeStaticFieldsIds, EQuestionnaireBodyAreas } from 'common/const/questionnaire.const';
import { IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  name: string;
  data: IQuestionnaireData[];
  onSubmit: (values: IFormValues) => void;
  handleSave: (comment: string) => void;
  patientAnswers?: IPatientAnswer[];
}

export const BedtimePainQuestions: React.FC<IComponentProps> = (props) => {
  const { name, data, onSubmit, handleSave, patientAnswers, questionnaire } = props;
  const [fields, setFields] = useState<FieldData[]>([]);
  const [tableData, setTableData] = useState<IQuestionnaireData[] | []>([]);

  useEffect(() => {
    if (patientAnswers) {
      const fields: FieldData[] = [];
      const table: IQuestionnaireData[] = [];

      patientAnswers.forEach((answer: IPatientAnswer, index: number) => {
        if (bedTimeStaticFieldsIds.includes(answer.name as string)) {
          fields.push({ name: answer.name, value: answer.value });
        } else {
          /* If data is empty it takes field names from patient answers. !tableData.find check prevents duplication of elements
           */
          if (!data && !tableData.find((data: IQuestionnaireData) => data.item === (answer.name as string))) {
            table.push({
              key: index,
              pain_area: EQuestionnaireBodyAreas[answer.name as keyof typeof EQuestionnaireBodyAreas],
              item: answer.name as string,
            });
          }
          // Populate dynamic fields with patient answers
          fields.push({ name: answer.name as string, value: answer.value });
        }
      });
      setTableData(table);
      setFields(fields);
    }
  }, [patientAnswers]);

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  const columns = [
    {
      title: 'Pain areas',
      dataIndex: 'pain_area',
    },
    {
      // eslint-disable-next-line
      title: () => (
        <div className="tooltip-container">
          <span>Typical pain level at bedtime</span>
          <Tooltip className="tooltip" title="tooltip message"></Tooltip>
        </div>
      ),
      render: (key: IQuestionnaireData) => <FormInput key={key.key} name={key.item} placeholder="0" type="number" suffix="/10" />,
    },
  ];

  return (
    <Form onFinish={onSubmit} fields={fields}>
      <h1 className="form__title">{name}</h1>
      <p className="form__description">
        Please describe how your pain would normally vary throughout the rest of the day from where it is now
      </p>
      <FormInput name={questionnaire.elements[0].textId} placeholder={questionnaire.elements[0].placeholder} />
      <div className="pain-questions-page__container">
        <Table className="pain-questions-page__table" columns={columns} dataSource={tableData} pagination={false} />
      </div>
      <div className="pain-questions-page__btns">
        <ManageBtns handleSave={handleSave} />
      </div>
    </Form>
  );
};
