/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect, useRef } from 'react';
import { FormInstance } from 'antd';
import { FormInput } from 'common/components/Form/FormInput';
import { FormRadio } from 'common/components/Form/FormRadio';
import { IFormValues, IQuestionnaireAnswer } from 'common/models/formBuilder.models';
import { AddOptionBtn } from 'common/components/Form/AddOptionBtn';
import { FormCheckbox } from 'common/components/Form/FormCheckbox';
import {
  baseValidationRules,
  maxSymptomsCount,
  noneValueForSymptomsQuestion,
  nonHeadSymptomsWithNoneOption,
  placeholders,
  textValidationRules,
} from 'common/const/questionnaire.const';
import { FormSelectSingle } from 'common/components/Form/FormSelectSingle';
import { FormDatePicker } from 'common/components/Form/FormDatePicker';
import { FormSelectGroup } from 'common/components/Form/FormSelectGroup';
import { compareFields } from 'common/utils/compareFields';
import { generateFormItemClassName } from 'common/utils/generateFormItemClassName';
import { FORMS_CUSTOM_ITEMS_MAX_COUNT } from 'common/config';
import { FormLookup } from 'common/components/Form/FormLookup';
import { IQuestionnaireElement, IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

const getAnswers = (answers: IQuestionnaireAnswer[], questionId: string) => {
  return answers.find((answer: IQuestionnaireAnswer) => answer.questionId === questionId)?.answers || [];
};

export const getFormControl = (
  formController: FormInstance<IFormValues>,
  questionnaireElement: IQuestionnaireElement,
  answers: IQuestionnaireAnswer[],
  elementBasedOn?: IQuestionnaireAnswer,
  elements?: IQuestionnaireElement[],
  additionalCheckboxValues?: string[]
): JSX.Element | null => {
  const { textId, type, question, answers: questionAnswers, required, hover, placeholder, validators } = questionnaireElement;

  switch (type) {
    case 'datePicker': {
      return <FormDatePicker name={textId} label={question} />;
    }
    case 'text': {
      const label = placeholders.includes(question) ? undefined : question;

      return (
        <FormInput
          name={textId}
          placeholder={question}
          label={label}
          type="text"
          rules={required === false ? undefined : textValidationRules()}
        />
      );
    }
    case 'radio': {
      if (!questionAnswers) {
        return null;
      }

      return (
        <FormRadio
          className={generateFormItemClassName(textId, type)}
          name={textId}
          label={question}
          radioButtons={questionAnswers}
          tooltip={hover}
        />
      );
    }
    case 'checkbox': {
      const patientAnswers = getAnswers(answers, textId);
      // Here we check if patient added any custom items and draw them alongside with initial
      const buttons: string[] = [...new Set([...questionAnswers, ...patientAnswers])];

      return (
        <FormCheckbox
          className="form__checkbox"
          name={textId}
          label={question}
          checkboxButtons={buttons}
          additionalCheckboxValues={additionalCheckboxValues}
          required={required}
          formController={formController}
        />
      );
    }
    case 'number': {
      return (
        <FormInput
          name={textId}
          type="number"
          label={question}
          placeholder={placeholder || question}
          rules={!validators ? baseValidationRules() : undefined}
          validators={validators}
        />
      );
    }
    case 'checkbox_limited': {
      let baseAnswers: string[] | undefined = undefined;
      let diffCount: number | undefined = undefined;
      const checked = answers.find((answer: IQuestionnaireAnswer) => answer.questionId === textId)?.answers || [];

      if (textId === 'baseline_head_30') {
        // condition for head form
        // get answers from 27th question
        const bh27Answers = getAnswers(answers, 'baseline_head_27');
        // combine with answers from 29th question (it is basedOn question for 30th)
        baseAnswers = elementBasedOn ? [...bh27Answers, ...elementBasedOn.answers] : bh27Answers;
        // define difference between maxSymptomsCount and symptoms checked in 27th
        diffCount = maxSymptomsCount - bh27Answers.length;
      } else {
        // condition for nonhead form
        baseAnswers = elementBasedOn ? elementBasedOn?.answers : undefined;
        diffCount = maxSymptomsCount;
      }

      if (checked.length > diffCount) {
        checked.pop();
      }

      if (!baseAnswers || diffCount === undefined || (baseAnswers && baseAnswers.length <= maxSymptomsCount)) {
        return null;
      }

      return (
        <FormCheckbox
          className="form__checkbox"
          name={textId}
          label={getQuestionTextForCheckboxLimitedQuestion(diffCount)}
          checkboxButtons={elementBasedOn?.answers}
          checked={checked}
          maximumChecked={diffCount}
          formController={formController}
          rules={[{ type: 'array', min: diffCount, message: `Please select ${diffCount - checked.length} more symptoms ` }]}
        />
      );
    }
    case 'select': {
      return elementBasedOn?.answers?.length || questionAnswers ? (
        <FormSelectSingle
          name={textId}
          options={elementBasedOn?.answers || questionAnswers}
          label={question}
          placeholder={question}
        />
      ) : (
        <div></div>
      );
    }
    case 'select_group': {
      if (textId === 'baseline_head_31' || textId === 'baseline_head_38') {
        const answersFrom_27 = getAnswers(answers, 'baseline_head_27');
        const answersFrom_29 = getAnswers(answers, 'baseline_head_29');
        const answersFrom_30 = getAnswers(answers, 'baseline_head_30');
        let answersToShow: string[] = [];

        if (answersFrom_30.length) {
          answersToShow = [...answersFrom_27, ...answersFrom_30];
        } else {
          answersToShow = [...answersFrom_27, ...answersFrom_29];
        }

        if (!answersToShow.length) {
          return null;
        }

        return (
          <FormSelectGroup
            name={textId}
            label={question}
            options={questionAnswers}
            answers={answersToShow}
            placeholder={placeholder}
          />
        );
      } else {
        const fallbackQuestionnareId_25 = elements?.find((element: IQuestionnaireElement) =>
          element.textId.endsWith('_25')
        )?.textId;
        const fallbackQuestionnareId_26 = elements?.find((element: IQuestionnaireElement) =>
          element.textId.endsWith('_26')
        )?.textId;
        const fallbackAnswers_25 = fallbackQuestionnareId_25 ? getAnswers(answers, fallbackQuestionnareId_25) : [];
        const fallbackAnswers_26 = fallbackQuestionnareId_26 ? getAnswers(answers, fallbackQuestionnareId_26) : [];
        const fallbackAnswers =
          (fallbackAnswers_26.length && fallbackAnswers_26) || (fallbackAnswers_25.length && fallbackAnswers_25) || [];

        if (!fallbackAnswers.length) {
          return null;
        }

        if (fallbackAnswers.includes(noneValueForSymptomsQuestion)) {
          return null;
        }

        return (
          <FormSelectGroup
            name={textId}
            label={question}
            options={questionAnswers}
            answers={fallbackAnswers}
            placeholder={placeholder}
          />
        );
      }
    }
    case 'lookup': {
      return <FormLookup name={textId} label={question} />;
    }
    default:
      return null;
  }
};

export const renderFormControls = (
  elements: IQuestionnaireElement[],
  answers: IQuestionnaireAnswer[],
  addItemResponses: (question: string, value: string) => void,
  additionalCheckboxValues: string[],
  formController: FormInstance<IFormValues>
): React.ReactNode => {
  const canAddCustomItem = additionalCheckboxValues.length < FORMS_CUSTOM_ITEMS_MAX_COUNT;

  return elements.map((item) => {
    const { textId, question, trigger, basedOn, addOption, type } = item;

    switch (true) {
      case !!trigger: {
        const element = answers.find((answer: IQuestionnaireAnswer) => answer.questionId === trigger?.questionId);
        const elementBasedOn = basedOn?.length
          ? answers.find((answer: IQuestionnaireAnswer) => answer.questionId === basedOn)
          : undefined;

        if (
          trigger?.compare &&
          typeof trigger.value === 'number' &&
          compareFields(trigger.compare, trigger.value, element?.answers)
        ) {
          return (
            <div key={textId}>
              {getFormControl(formController, item, answers, elementBasedOn, elements, additionalCheckboxValues)}
              {addOption && <AddOptionBtn name={addOption} question={question} onChange={addItemResponses} />}
            </div>
          );
        }

        if (trigger?.value instanceof Array) {
          if (element?.answers instanceof Array) {
            const intersections = element?.answers.filter((value: string) => (item.trigger?.value as string[]).includes(value));
            // We have questions  with custom logic if the None value is selected
            const isNoneOptionChecked = element?.answers.includes(noneValueForSymptomsQuestion);

            if (nonHeadSymptomsWithNoneOption.includes(textId) && isNoneOptionChecked) {
              return;
            }

            if (intersections && intersections.length) {
              return (
                <div key={textId}>
                  {getFormControl(formController, item, answers, elementBasedOn, elements, additionalCheckboxValues)}
                  {canAddCustomItem && addOption && (
                    <AddOptionBtn name={addOption} question={question} onChange={addItemResponses} />
                  )}
                </div>
              );
            }
          } else {
            return (
              (element?.answers.includes(trigger?.value.join()) || trigger?.value.includes(element?.answers)) && (
                <div key={textId}>
                  {getFormControl(formController, item, answers, elementBasedOn, elements, additionalCheckboxValues)}
                  {canAddCustomItem && addOption && (
                    <AddOptionBtn name={addOption} question={question} onChange={addItemResponses} />
                  )}
                </div>
              )
            );
          }
        }

        return null;
      }
      case !!basedOn: {
        const element = answers.find((answer: IQuestionnaireAnswer) => answer.questionId === basedOn);

        return <div key={textId}>{getFormControl(formController, item, answers, element, elements, undefined)}</div>;
      }
      case type === 'subhead': {
        const title = useRef<HTMLSpanElement>(null);

        useEffect(() => {
          if (question && title.current) {
            title.current.innerHTML = question;
          }
        }, [question]);

        return (
          <div key={textId} className="form__subhead mb-8">
            <span ref={title}>{question}</span>
          </div>
        );
      }
      case type === 'tip': {
        const labelRef = useRef<HTMLSpanElement>(null);

        // eslint-disable-next-line sonarjs/no-identical-functions
        useEffect(() => {
          if (question && labelRef.current) {
            labelRef.current.innerHTML = question;
          }
        }, [question]);

        return (
          <div key={textId} className="form__tip mb-8">
            <span ref={labelRef}>{question}</span>
          </div>
        );
      }
      default: {
        // Check for None option for disabling "Add symptom" button on symptom question
        const isNoneOptionChecked =
          nonHeadSymptomsWithNoneOption.includes(textId) &&
          answers.find((answer) => answer.questionId === textId)?.answers.includes(noneValueForSymptomsQuestion);

        return (
          <div key={textId}>
            {getFormControl(formController, item, answers, undefined, elements, additionalCheckboxValues)}
            {canAddCustomItem && addOption && (
              <AddOptionBtn name={addOption} question={question} onChange={addItemResponses} disabled={isNoneOptionChecked} />
            )}
          </div>
        );
      }
    }
  });
};

export const getQuestionTextForCheckboxLimitedQuestion = (simptomsCount: number): string => {
  return `Which are your ${simptomsCount} most bothersome symptoms to track on a daily basis?`;
};

const getAnswersForRelatedSelectGroups = (
  formController: FormInstance<IFormValues>,
  questionnaire: IQuestionnaireModel,
  relatedQuestionIds: string[],
  keysToSet: string[]
) => {
  const answersForRelatedSelectGroups: IQuestionnaireAnswer[] = [];

  // loop through relatedQuestionIds
  relatedQuestionIds.forEach((relatedQuestionId) => {
    // get current value from form for specific question
    const { [relatedQuestionId]: formValues } = formController.getFieldsValue([relatedQuestionId]);
    const newValues: IFormValues = {};

    // compare current value with new set ogf key to filter and add updated values
    keysToSet.forEach((keyToSet) => {
      if (formValues && formValues[keyToSet]) {
        newValues[keyToSet] = formValues[keyToSet];
      }
    });

    const mappedAnswers = Object.entries(newValues).map(([key, value]) => key && { [key]: value });
    const elementInForm = questionnaire.elements.find((element) => element.textId === relatedQuestionId);

    if (mappedAnswers.length) {
      answersForRelatedSelectGroups.push({
        questionId: relatedQuestionId,
        question: elementInForm?.question,
        answers: mappedAnswers,
      });
    }
  });

  return answersForRelatedSelectGroups;
};

export const handle_baseline_head_27 = (
  formController: FormInstance<IFormValues>,
  questionnaire: IQuestionnaireModel,
  answers: IQuestionnaireAnswer[],
  answerValue: string[]
): IQuestionnaireAnswer[] => {
  const questionRelatedTo_27 = ['baseline_head_28', 'baseline_head_31', 'baseline_head_38'];
  formController.resetFields(['baseline_head_28']);

  // Remove answers of related questions. We will set new values for the futher
  let updatedAnswers = answers.filter((answer: IQuestionnaireAnswer) => !questionRelatedTo_27.includes(answer.questionId));

  if (!answerValue.length) {
    updatedAnswers = updatedAnswers.filter(
      (updatedAnswer: IQuestionnaireAnswer) => updatedAnswer.questionId !== 'baseline_head_27'
    );
  }

  const { baseline_head_29: valuesFor_29 } = formController.getFieldsValue(['baseline_head_29']);
  const unitedValuesToSet: string[] = valuesFor_29 ? [...answerValue, ...valuesFor_29] : answerValue;

  if (unitedValuesToSet.length <= maxSymptomsCount) {
    updatedAnswers = updatedAnswers.filter(
      (updatedAnswer: IQuestionnaireAnswer) => updatedAnswer.questionId !== 'baseline_head_30'
    );
  }

  const answersFor_31_and_38 = getAnswersForRelatedSelectGroups(
    formController,
    questionnaire,
    ['baseline_head_31', 'baseline_head_38'],
    unitedValuesToSet
  );

  return [...updatedAnswers, ...answersFor_31_and_38];
};

export const handle_baseline_head_29 = (
  formController: FormInstance<IFormValues>,
  questionnaire: IQuestionnaireModel,
  answers: IQuestionnaireAnswer[],
  answerValue: string[]
): IQuestionnaireAnswer[] => {
  const questionRelatedTo_29 = ['baseline_head_30', 'baseline_head_31', 'baseline_head_38'];

  // Remove answers of related questions. We will set new values for the futher
  let updatedAnswers = answers.filter((answer: IQuestionnaireAnswer) => !questionRelatedTo_29.includes(answer.questionId));

  if (!answerValue.length) {
    updatedAnswers = updatedAnswers.filter(
      (updatedAnswer: IQuestionnaireAnswer) => updatedAnswer.questionId !== 'baseline_head_29'
    );
  }

  const { baseline_head_27: valuesFor_27, baseline_head_30: valuesFor_30 } = formController.getFieldsValue([
    'baseline_head_27',
    'baseline_head_30',
  ]);

  const elementInForm_30 = questionnaire.elements.find((element) => element.textId === 'baseline_head_30');
  const newValuesFor_30 = valuesFor_30?.filter((answer30: string) => answerValue.includes(answer30)) || [];

  if (newValuesFor_30.length) {
    updatedAnswers.push({ questionId: 'baseline_head_30', question: elementInForm_30?.question, answers: newValuesFor_30 });
  }

  const unitedValuesToSet = valuesFor_27 ? [...answerValue, ...valuesFor_27] : answerValue;

  if (unitedValuesToSet.length < maxSymptomsCount) {
    updatedAnswers = updatedAnswers.filter(
      (updatedAnswer: IQuestionnaireAnswer) => updatedAnswer.questionId !== 'baseline_head_30'
    );
  }

  const answersFor_31_and_38 = getAnswersForRelatedSelectGroups(
    formController,
    questionnaire,
    ['baseline_head_31', 'baseline_head_38'],
    unitedValuesToSet
  );

  return [...updatedAnswers, ...answersFor_31_and_38];
};

export const handle_baseline_head_30 = (
  formController: FormInstance<IFormValues>,
  questionnaire: IQuestionnaireModel,
  answers: IQuestionnaireAnswer[],
  answerValue: string[]
): IQuestionnaireAnswer[] => {
  const questionRelatedTo30 = ['baseline_head_31', 'baseline_head_38'];

  // Remove answers of related questions. We will set new values for the futher
  let updatedAnswers = answers.filter((answer: IQuestionnaireAnswer) => !questionRelatedTo30.includes(answer.questionId));

  if (!answerValue.length) {
    updatedAnswers = updatedAnswers.filter(
      (updatedAnswer: IQuestionnaireAnswer) => updatedAnswer.questionId !== 'baseline_head_30'
    );
  }

  const { baseline_head_27: valuesFor_27, baseline_head_29: valuesFor_29 } = formController.getFieldsValue([
    'baseline_head_27',
    'baseline_head_29',
  ]);
  const unitedValuesToSet = answerValue.length ? [...answerValue, ...valuesFor_27] : [[...valuesFor_27, ...valuesFor_29]];

  const answersFor_31_and_38 = getAnswersForRelatedSelectGroups(
    formController,
    questionnaire,
    ['baseline_head_31', 'baseline_head_38'],
    unitedValuesToSet
  );

  return [...updatedAnswers, ...answersFor_31_and_38];
};

export const handle_baseline_nonhead_25 = (
  formController: FormInstance<IFormValues>,
  questionnaire: IQuestionnaireModel,
  answers: IQuestionnaireAnswer[],
  answeredQuestionId: string,
  answerValue: string[]
): IQuestionnaireAnswer[] => {
  // Get bodypart for futher using for generation of related question ids
  // Example: 'baseline_body_right_arm_25' -> 'baseline_body_right_arm'
  const bodyPart = answeredQuestionId.split('_').slice(0, -1).join('_');
  const questionRelatedTo_25 = [`${bodyPart}_26`, `${bodyPart}_27`, `${bodyPart}_34`];
  const elementInForm_25 = questionnaire.elements.find((element) => element.textId === answeredQuestionId);

  // Remove answers of related questions. We will set new values for the futher
  let updatedAnswers = answers.filter((answer: IQuestionnaireAnswer) => !questionRelatedTo_25.includes(answer.questionId));
  updatedAnswers = updatedAnswers.filter(
    (updatedAnswer: IQuestionnaireAnswer) => updatedAnswer.questionId !== answeredQuestionId
  );

  if (!answerValue.length) {
    updatedAnswers = updatedAnswers.filter(
      (updatedAnswer: IQuestionnaireAnswer) => updatedAnswer.questionId !== answeredQuestionId
    );
  }

  if (answerValue.includes(noneValueForSymptomsQuestion)) {
    formController.setFields([{ name: answeredQuestionId, value: [noneValueForSymptomsQuestion] }]);

    return [
      ...updatedAnswers,
      {
        questionId: answeredQuestionId,
        question: elementInForm_25?.question,
        answers: [noneValueForSymptomsQuestion],
      },
    ];
  } else {
    updatedAnswers = [
      ...updatedAnswers,
      {
        questionId: answeredQuestionId,
        question: elementInForm_25?.question,
        answers: answerValue,
      },
    ];
  }

  const { [`${bodyPart}_26`]: valuesFor_26 } = formController.getFieldsValue([`${bodyPart}_26`]);
  const elementInForm_26 = questionnaire.elements.find((element) => element.textId === `${bodyPart}_26`);
  const newValuesFor_26 = valuesFor_26?.filter((answer26: string) => answerValue.includes(answer26)) || [];

  if (newValuesFor_26.length) {
    updatedAnswers.push({ questionId: `${bodyPart}_26`, question: elementInForm_26?.question, answers: newValuesFor_26 });
  }

  if (answerValue.length <= maxSymptomsCount) {
    updatedAnswers = updatedAnswers.filter(
      (updatedAnswer: IQuestionnaireAnswer) => updatedAnswer.questionId !== `${bodyPart}_26`
    );
  }

  const answersFor_27_and_34 = getAnswersForRelatedSelectGroups(
    formController,
    questionnaire,
    [`${bodyPart}_27`, `${bodyPart}_34`],
    answerValue
  );

  return [...updatedAnswers, ...answersFor_27_and_34];
};

export const handle_baseline_nonhead_26 = (
  formController: FormInstance<IFormValues>,
  questionnaire: IQuestionnaireModel,
  answers: IQuestionnaireAnswer[],
  answeredQuestionId: string,
  answerValue: string[]
): IQuestionnaireAnswer[] => {
  // Get bodypart for futher using for generation of related question ids
  // Example: 'baseline_body_right_arm_25' -> 'baseline_body_right_arm'
  const bodyPart = answeredQuestionId.split('_').slice(0, -1).join('_');
  const questionRelatedTo_26 = [`${bodyPart}_27`, `${bodyPart}_34`];

  // Remove answers of related questions. We will set new values for the futher
  let updatedAnswers = answers.filter((answer: IQuestionnaireAnswer) => !questionRelatedTo_26.includes(answer.questionId));

  if (!answerValue.length) {
    updatedAnswers = updatedAnswers.filter(
      (updatedAnswer: IQuestionnaireAnswer) => updatedAnswer.questionId !== answeredQuestionId
    );
  }

  const { [`${bodyPart}_25`]: valuesFor_25 } = formController.getFieldsValue([`${bodyPart}_25`]);
  const valuesToSet = answerValue.length ? answerValue : valuesFor_25;

  const answersFor_27_and_34 = getAnswersForRelatedSelectGroups(
    formController,
    questionnaire,
    [`${bodyPart}_27`, `${bodyPart}_34`],
    valuesToSet
  );

  return [...updatedAnswers, ...answersFor_27_and_34];
};
