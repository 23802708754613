/* eslint-disable sonarjs/no-duplicate-string */
import {
  EChestAbdomenBack,
  EFeet,
  EHeadNeck,
  ELeftArm,
  ELeftHand,
  ELegs,
  ERightArm,
  ERightHand,
} from 'common/const/painAreas/bodyParts.enums';
import {
  EHeadNeckAreas,
  ELeftArmAreas,
  ELeftFootAreas,
  ELeftHandAreas,
  ELeftLegAreas,
  ERightArmAreas,
  ERightFootAreas,
  ERightHandAreas,
  ERightLegAreas,
  ETorsoAreas,
} from 'common/const/painAreas/painAreas.enums';

export const heatmapAreas: { [key: string]: any } = {
  [EHeadNeck['Head/Neck (Front)']]: {
    '#9b51e0': EHeadNeckAreas['Top of head'],
    '#2d9cdb': EHeadNeckAreas['Forehead (mid- to right-side)'],
    '#27ae60': EHeadNeckAreas['Forehead (mid- to left-side)'],
    '#f2994a': EHeadNeckAreas['Eyes'],
    '#eb5757': EHeadNeckAreas['Neck (front)'],
  },
  [EHeadNeck['Head/Neck (Back)']]: {
    '#9b51e0': EHeadNeckAreas['Top of head'],
    '#23846c': EHeadNeckAreas['Back of Head (left side)'],
    '#845123': EHeadNeckAreas['Back of Head (right side)'],
    '#e051af': EHeadNeckAreas['Upper neck (back)'],
    '#287b9f': EHeadNeckAreas['Lower neck (back)'],
  },
  [EHeadNeck['Head/Neck (Right)']]: {
    '#9b51e0': EHeadNeckAreas['Top of head'],
    '#845123': EHeadNeckAreas['Back of Head (right side)'],
    '#2d9cdb': EHeadNeckAreas['Forehead (mid- to right-side)'],
    '#842346': EHeadNeckAreas['Right side of head'],
  },
  [EHeadNeck['Head/Neck (Left)']]: {
    '#9b51e0': EHeadNeckAreas['Top of head'],
    '#23846c': EHeadNeckAreas['Back of Head (left side)'],
    '#27ae60': EHeadNeckAreas['Forehead (mid- to left-side)'],
    '#cec041': EHeadNeckAreas['Left side of head'],
  },
  [EHeadNeck['Head/Neck (Top)']]: {
    '#9b51e0': EHeadNeckAreas['Top of head'],
  },
  [ERightArm['Right Arm (Front)']]: {
    '#883fff': ERightArmAreas['Right Lower Arm'],
    '#3f75ff': ERightArmAreas['Right Elbow'],
    '#3ff3ff': ERightArmAreas['Right Upper Arm'],
    '#ff3f3f': ERightArmAreas['Right Shoulder'],
  },
  [ERightArm['Right Arm (Back)']]: {
    '#883fff': ERightArmAreas['Right Lower Arm'],
    '#3f75ff': ERightArmAreas['Right Elbow'],
    '#3ff3ff': ERightArmAreas['Right Upper Arm'],
    '#ff3f3f': ERightArmAreas['Right Shoulder'],
  },
  [ELeftArm['Left Arm (Front)']]: {
    '#462281': ELeftArmAreas['Left Lower Arm'],
    '#24459b': ELeftArmAreas['Left Elbow'],
    '#20878d': ELeftArmAreas['Left Upper Arm'],
    '#961d1d': ELeftArmAreas['Left Shoulder'],
  },
  [ELeftArm['Left Arm (Back)']]: {
    '#462281': ELeftArmAreas['Left Lower Arm'],
    '#24459b': ELeftArmAreas['Left Elbow'],
    '#20878d': ELeftArmAreas['Left Upper Arm'],
    '#9d2626': ELeftArmAreas['Left Shoulder'],
  },
  [ERightHand['Right Hand (Front)']]: {
    '#57ebb6': ERightHandAreas['Right Hand'],
    '#d93fff': ERightHandAreas['Right Wrist'],
  },
  [ERightHand['Right Hand (Back)']]: {
    '#57ebb6': ERightHandAreas['Right Hand'],
    '#d93fff': ERightHandAreas['Right Wrist'],
  },
  [ELeftHand['Left Hand (Front)']]: {
    '#245c48': ELeftHandAreas['Left Hand'],
    '#852e9b': ELeftHandAreas['Left Wrist'],
  },
  [ELeftHand['Left Hand (Back)']]: {
    '#245c48': ELeftHandAreas['Left Hand'],
    '#852e9b': ELeftHandAreas['Left Wrist'],
  },
  [EChestAbdomenBack['Chest/Abdomen']]: {
    '#11b597': ETorsoAreas['Right Chest'],
    '#0c5d4f': ETorsoAreas['Left Chest'],
    '#ff9b3f': ETorsoAreas['Right Ribs/Chest'],
    '#ffe03f': ETorsoAreas['Right Abdomen'],
    '#995b22': ETorsoAreas['Left Ribs/Chest'],
    '#978421': ETorsoAreas['Left Abdomen'],
    '#961d1d': ELeftArmAreas['Left Shoulder'],
    '#ff3f3f': ERightArmAreas['Right Shoulder'],
  },
  [EChestAbdomenBack['Back']]: {
    '#704d2e': ETorsoAreas['Left Middle Back'],
    '#ac8b6d': ETorsoAreas['Right Middle Back'],
    '#1c2647': ETorsoAreas['Left Lower Back'],
    '#565d76': ETorsoAreas['Right Lower Back'],
    '#362d16': ETorsoAreas['Coccyx'],
    '#69715a': ELeftLegAreas['Left Buttock'],
    '#a3ad8f': ERightLegAreas['Right Buttock'],
    '#9d2626': ELeftArmAreas['Left Shoulder'],
    '#ff3f3f': ERightArmAreas['Right Shoulder'],
  },
  [EChestAbdomenBack['Left']]: {
    '#0c5d4f': ETorsoAreas['Left Chest'],
    '#995b22': ETorsoAreas['Left Ribs/Chest'],
    '#978421': ETorsoAreas['Left Abdomen'],
    '#69715a': ELeftLegAreas['Left Buttock'],
    '#961d1d': ELeftArmAreas['Left Shoulder'],
  },
  [EChestAbdomenBack['Right']]: {
    '#11b597': ETorsoAreas['Right Chest'],
    '#ff9b3f': ETorsoAreas['Right Ribs/Chest'],
    '#ffe03f': ETorsoAreas['Right Abdomen'],
    '#a3ad8f': ERightLegAreas['Right Buttock'],
    '#ff3f3f': ERightArmAreas['Right Shoulder'],
  },
  [ELegs['Legs (Front)']]: {
    '#32418b': ETorsoAreas['Genitalia/Pubic Region'],
    '#c1ff3f': ERightLegAreas['Right Upper Leg'],
    '#6f9422': ELeftLegAreas['Left Upper Leg'],
    '#ce6692': ERightLegAreas['Right Knee'],
    '#20b4ab': ELeftLegAreas['Left Knee'],
    '#5fe2ff': ERightLegAreas['Right Lower Leg'],
    '#ffd25f': ELeftLegAreas['Left Lower Leg'],
  },
  [ELegs['Legs (Back)']]: {
    '#69715a': ELeftLegAreas['Left Buttock'],
    '#a3ad8f': ERightLegAreas['Right Buttock'],
    '#6f9422': ELeftLegAreas['Left Upper Leg'],
    '#c1ff3f': ERightLegAreas['Right Upper Leg'],
    '#20b4ab': ELeftLegAreas['Left Knee'],
    '#ce6692': ERightLegAreas['Right Knee'],
    '#ffd25f': ELeftLegAreas['Left Lower Leg'],
    '#5fe2ff': ERightLegAreas['Right Lower Leg'],
  },
  [ELegs['Left Leg']]: {
    '#69715a': ELeftLegAreas['Left Buttock'],
    '#6f9422': ELeftLegAreas['Left Upper Leg'],
    '#20b4ab': ELeftLegAreas['Left Knee'],
    '#ffd25f': ELeftLegAreas['Left Lower Leg'],
  },
  [ELegs['Right Leg']]: {
    '#a3ad8f': ERightLegAreas['Right Buttock'],
    '#c1ff3f': ERightLegAreas['Right Upper Leg'],
    '#ce6692': ERightLegAreas['Right Knee'],
    '#5fe2ff': ERightLegAreas['Right Lower Leg'],
  },
  [EFeet['Feet (Front)']]: {
    '#e50000': ERightFootAreas['Right Ankle'],
    '#ff6b00': ERightFootAreas['Right Foot'],
    '#643000': ELeftFootAreas['Left Ankle'],
    '#b49320': ELeftFootAreas['Left Foot'],
  },
  [EFeet['Feet (Back)']]: {
    '#ff6b00': ERightFootAreas['Right Foot'],
    '#b49320': ELeftFootAreas['Left Foot'],
  },
};
