import React, { useEffect, useState } from 'react';
import { Button, Checkbox } from 'antd';
import { IBaseParams, IFormValues, IQuestionnaireAnswer } from 'common/models/formBuilder.models';
import { DTS_ANSWERS_LIMIT } from 'common/config';
import { TextRepresentation } from 'common/components/Diary/DTS/TreatmentTextRepresentation';
import { TreatmentSummaryModal } from 'common/components/Diary/DTS/TreatmentSummaryModal';
import { ReactComponent as AddIcon } from 'app/assets/images/svg/add-btn.svg';
import { IQuestionnaireElement, IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  handleSubmit: (params: IBaseParams, update?: boolean) => void;
}

export const DailyTreatmentSummaryMobile: React.FC<IComponentProps> = (props) => {
  const { questionnaire, handleSubmit } = props;
  // True on first visit, further - patientAnswer dependent. Reversed because 1st element specifies answer whether therapy wasn't taken
  const [therapyWasUsed, setTherapyWasUsed] = useState<boolean>(!questionnaire.elements[0].patientAnswers);
  const [answers, setAnswers] = useState<IFormValues[]>([]);
  const [modalItem, setModalItem] = useState<IQuestionnaireElement | null>(null);
  const [canAddCycle, setCanAddCycle] = useState<boolean>(true);

  useEffect(() => {
    if (therapyWasUsed) {
      const patientAnswers: IFormValues[] = [];

      questionnaire.elements.forEach(
        (element: IQuestionnaireElement) =>
          element?.patientAnswers?.length > 0 &&
          patientAnswers.push({ [element.textId]: Object.values(element.patientAnswers)[0] as string[] })
      );
      setAnswers(patientAnswers);
    }
  }, []);

  useEffect(() => {
    let answersCount = 0;
    answers.forEach((answer: IFormValues) => (answersCount += Object.values(answer).flat().length));

    setCanAddCycle(answersCount < DTS_ANSWERS_LIMIT);
  }, [answers]);

  useEffect(() => {
    handleSubmit({ elements: generateElementsArray() }, true);
  }, [answers]);

  const generateElementsArray = () => {
    const elements: IQuestionnaireAnswer[] = [];
    const checkboxElement = questionnaire.elements[0];
    // .slice(1) ignores first element which specifies the "I didn't use therapy yet today" checkbox
    questionnaire.elements.slice(1).forEach((element: IQuestionnaireElement) => {
      const answer = therapyWasUsed ? answers.find((answer: IFormValues) => Object.keys(answer)[0] === element.textId) : null;

      elements.push({
        questionId: element.textId,
        answers: answer ? Object.values(answer).flat() : null,
        question: element.question,
      });
    });

    if (therapyWasUsed) {
      elements.push({
        questionId: checkboxElement.textId,
        answers: false,
        question: checkboxElement.question,
      });
    } else {
      elements.push({
        questionId: checkboxElement.textId,
        answers: true,
        question: checkboxElement.question,
      });
    }

    return elements;
  };

  const toggleTherapyWasUsed = () => {
    setTherapyWasUsed(!therapyWasUsed);
  };

  const removeData = (id: string, index: number) => {
    const item = answers.find((answer) => Object.keys(answer)[0] === id);
    if (item) {
      const values = item[id].filter((_: IFormValues, pos: number) => pos !== index);
      const answersFiltered = answers.filter((answer) => {
        return Object.keys(answer)[0] !== id;
      });
      if (values.length) {
        setAnswers(answersFiltered.concat({ [id]: values }));
      } else {
        setAnswers(answersFiltered);
      }
    }
  };

  const submitData = () => {
    handleSubmit({ elements: generateElementsArray() });
  };

  return (
    <div className="dts">
      <h1 className="layout-mobile__title">Daily Treatment Summary</h1>
      <Checkbox className="mb-8" name="therapy_was_used" onChange={toggleTherapyWasUsed} defaultChecked={!therapyWasUsed}>
        I didn&apos;t use therapy yet today
      </Checkbox>
      {therapyWasUsed &&
        questionnaire &&
        questionnaire.elements.slice(1).map((element: IQuestionnaireElement) => (
          <div className="dts__treatment_item" key={element.textId}>
            <span className="dts__treatment_item__title">{element.question}</span>
            <TextRepresentation
              element={answers.find((answer: IFormValues) => Object.keys(answer)[0] === element.textId)}
              removeData={removeData}
            />
            {canAddCycle && (
              <Button onClick={() => setModalItem(element)}>
                {
                  <div className="mr-8 ml-8">
                    <AddIcon className="mr-4" />
                    <span>Add treatment cycle</span>
                  </div>
                }
              </Button>
            )}
          </div>
        ))}
      <Button className="layout-mobile__btn" type="primary" onClick={submitData} disabled={therapyWasUsed && answers.length < 1}>
        Next
      </Button>
      {modalItem && (
        <TreatmentSummaryModal modalItem={modalItem} setModalItem={setModalItem} setAnswers={setAnswers} answers={answers} />
      )}
    </div>
  );
};
