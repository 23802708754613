import { APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import message from 'antd/es/message';
import { EActionsTypes } from '@axmit/redux-communications';
import { ICopyAnswersRequest } from 'common/models/formBuilder.models';
import { IQuestionnaireModel, IQuestionnareCreateParams } from 'entities/PatientSessions/PatientSession.models';
import {
  patientSessionsPrevQuestionnareTransport,
  patientSessionsPartialQuestionnareTransport,
  patientSessionsQuestionnareTransport,
  patientSessionsTransport,
  patientSessionsCopyAnswers,
} from 'entities/PatientSessions/PatientSession.transport';

function setOldData<Data, Params, Errors>(
  response: Data,
  payload: Params,
  branchState: StoreBranch<Data, Params, Errors>
): Data | undefined {
  return branchState.data || undefined;
}

const namespace = 'patientSessions';

export interface IPatientSessionsConnectedProps {
  patientSessionsModel: StoreBranch<IQuestionnaireModel>;
  setPatientSessionsModel(params: IQuestionnareCreateParams): void;
  getPatientSessionsModel(id: string): void;
  getPrevPatientSessionsModel(id: string): void;
  updatePatientSessionsModel(params: IQuestionnareCreateParams): void;
  getAnswersCopyPatientSessionsModel(params: ICopyAnswersRequest): Promise<IQuestionnaireModel>;
}

const modelApiProvider = [
  new APIProvider(EActionsTypes.set, patientSessionsTransport.add, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
  new APIProvider(EActionsTypes.get, patientSessionsQuestionnareTransport.get, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
  new APIProvider('getPrev', patientSessionsPrevQuestionnareTransport.get, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
  new APIProvider(EActionsTypes.update, patientSessionsPartialQuestionnareTransport, {
    preRequestDataMapper: setOldData,
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
  new APIProvider('getAnswersCopy', patientSessionsCopyAnswers, {
    onFail: (response) => {
      response?.message && message.error(response.message);
    },
  }),
];

const branches = [new Branch('model', modelApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches,
});

export const communicationPatientSessions = buildCommunication<IPatientSessionsConnectedProps>(strategy);
