/* eslint-disable sonarjs/no-duplicate-string */
export const enum ETorsoAreas {
  'Right Chest' = 'Right Chest',
  'Left Chest' = 'Left Chest',
  'Right Ribs/Chest' = 'Right Ribs/Chest',
  'Left Ribs/Chest' = 'Left Ribs/Chest',
  'Right Abdomen' = 'Right Abdomen',
  'Left Abdomen' = 'Left Abdomen',
  'Genitalia/Pubic Region' = 'Genitalia/Pubic Region',
  'Right Shoulder' = 'Right Shoulder',
  'Left Shoulder' = 'Left Shoulder',
  'Coccyx' = 'Coccyx',
  'Left Middle Back' = 'Left Middle Back',
  'Right Middle Back' = 'Right Middle Back',
  'Left Lower Back' = 'Left Lower Back',
  'Right Lower Back' = 'Right Lower Back',
}

export const enum ERightArmAreas {
  'Right Shoulder' = 'Right Shoulder',
  'Right Upper Arm' = 'Right Upper Arm',
  'Right Elbow' = 'Right Elbow',
  'Right Lower Arm' = 'Right Lower Arm',
}

export const enum ERightHandAreas {
  'Right Wrist' = 'Right Wrist',
  'Right Hand' = 'Right Hand',
}

export const enum ELeftArmAreas {
  'Left Shoulder' = 'Left Shoulder',
  'Left Upper Arm' = 'Left Upper Arm',
  'Left Elbow' = 'Left Elbow',
  'Left Lower Arm' = 'Left Lower Arm',
}

export const enum ELeftHandAreas {
  'Left Wrist' = 'Left Wrist',
  'Left Hand' = 'Left Hand',
}

export const enum ERightLegAreas {
  'Right Upper Leg' = 'Right Upper Leg',
  'Right Knee' = 'Right Knee',
  'Right Lower Leg' = 'Right Lower Leg',
  'Right Buttock' = 'Right Buttock',
}

export const enum ERightFootAreas {
  'Right Ankle' = 'Right Ankle',
  'Right Foot' = 'Right Foot',
}

export const enum ELeftLegAreas {
  'Left Upper Leg' = 'Left Upper Leg',
  'Left Knee' = 'Left Knee',
  'Left Lower Leg' = 'Left Lower Leg',
  'Left Buttock' = 'Left Buttock',
}

export const enum ELeftFootAreas {
  'Left Ankle' = 'Left Ankle',
  'Left Foot' = 'Left Foot',
}

export const enum EHeadNeckAreas {
  'Lower neck (back)' = 'Lower neck (back)',
  'Upper neck (back)' = 'Upper neck (back)',
  'Neck (front)' = 'Neck (front)',
  'Back of Head (right side)' = 'Back of Head (right side)',
  'Back of Head (left side)' = 'Back of Head (left side)',
  'Right side of head' = 'Right side of head',
  'Left side of head' = 'Left side of head',
  'Top of head' = 'Top of head',
  'Forehead (mid- to right-side)' = 'Forehead (mid- to right-side)',
  'Forehead (mid- to left-side)' = 'Forehead (mid- to left-side)',
  'Eyes' = 'Eyes',
  'Behind Eyes' = 'Behind Eyes',
}
