import { Col, Row, Spin } from 'antd';
import { SketchField } from 'react-sketch3';
import React, { useEffect, useState } from 'react';
import { FormButton } from 'common/components/Form/FormButtton';
import { PainAreasDrawing } from 'common/components/Body/PainAreasDrawing';
import { PainAreasList } from 'common/components/Body/PainAreasList';
import { AdditionalComments } from 'common/components/Form/FormAdditionalComments';
import { maleAreas } from 'common/const/painAreas/painAreasMale.const';
import { femaleAreas } from 'common/const/painAreas/painAreasFemale.const';
import { IBodyPart } from 'common/models/painAreas.models';
import { IPainArea } from 'common/models/body.models';
import { IAreaData, IBaseParams, IBodyAnswer, IDetail } from 'common/models/formBuilder.models';
import { EPatientGender } from 'common/const/patientInfo.const';
import { painAreasProjections } from 'common/const/painAreas/painAreasProjections.const';
import { uploadImage } from 'common/utils/uploadImage';
import { mergeBase64Images } from 'common/utils/mergeBase64Images';
import { FormStatus } from 'common/components/Form/FormStatus';
import { PAIN_AREAS_PAGE_IMAGE_UPLOAD_ERROR_MESSAGE } from 'common/config';
import {
  communicationPatientSessions,
  IPatientSessionsConnectedProps,
} from 'entities/PatientSessions/PatientSessions.communication';

interface IComponentProps {
  id: string;
  sessionId: string;
  handleSubmit: (params: IBaseParams) => void;
}

type AllProps = IPatientSessionsConnectedProps & IComponentProps;

const PainAreas: React.FC<AllProps> = (props) => {
  const { patientSessionsModel, handleSubmit, id, sessionId } = props;
  const [additionalCommentsShown, setAdditionalCommentsShown] = useState<boolean>(false);
  const [bodyParts, setBodyParts] = useState<IBodyPart[]>([]);
  const [currentPart, setCurrentPart] = useState<IBodyPart>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [selectedAreas, setSelectedAreas] = useState<IPainArea[]>([]);
  const [canvasData, setCanvasData] = useState<string>('');
  const [imagesUploading, setImagesUploading] = useState<boolean>(false);
  const [uploadErrors, setUploadErrors] = useState<boolean>(false);
  const [sketchFieldRef, setSketchFieldRef] = useState<React.RefObject<SketchField>>();
  const { data, loading } = patientSessionsModel;
  const isFemale = data?.patient?.gender === EPatientGender.Female;
  const answers: IBodyAnswer[] = data?.elements[0]?.answers;
  const studyPatientId: number | undefined = data?.session?.studyPatientId;

  const toggleAdditionalComments = (): void => {
    setAdditionalCommentsShown(!additionalCommentsShown);
  };

  const onBackClick = (): void => {
    if (currentPart) {
      currentPart.canvasData = canvasData;
      bodyParts[currentIndex] = currentPart;
      setBodyParts(bodyParts);
    }
    setCurrentIndex(currentIndex - 1);
  };

  const onNextClick = async (): Promise<void> => {
    if (currentPart) {
      setImagesUploading(true);
      currentPart.canvasData = canvasData;

      // True if any shape was drawn
      const dataURL = sketchFieldRef?.current?.canUndo() ? sketchFieldRef?.current?.toDataURL() : '';
      if (dataURL.length) {
        currentPart.dataURL = await mergeBase64Images([currentPart.image, dataURL]);
      } else {
        currentPart.dataURL = undefined;
      }

      bodyParts[currentIndex] = currentPart;
      setBodyParts(bodyParts);

      if (currentIndex === bodyParts.length - 1) {
        const request: IBodyAnswer[] = [];
        const comments: Components.Schemas.CreatePatientQuestionnaireCommentDto[] = [];

        answers.forEach((answer: IBodyAnswer, index: number) => {
          const part: string = answer.part;
          const detail: IDetail[] = [];

          request.push(answer);

          painAreasProjections[part].forEach((entry: string) => {
            const part = bodyParts.find((part: IBodyPart) => part.name === entry);
            const data: IAreaData[] = [];

            if (part) {
              part.selectedAreas.forEach((entry: IPainArea) => {
                const type: string[] = [];
                if (entry.painMarksCount > 0) {
                  type.push('pain');
                }

                if (entry.scarMarksCount > 0) {
                  type.push('scar');
                }
                data.push({ pain_area: entry.name, type });
              });

              if (part.comments || part.dataURL) {
                detail.push({
                  projection: part.name,
                  data: data,
                  image: part.dataURL || '',
                });
              }
              request[index].detail = detail;
              comments.push({ textId: `${patientSessionsModel.data?.textId}${part.name}`, comment: part.comments });
            }
          });
        });

        try {
          await uploadImages(request);
          handleSubmit({ elements: [{ questionId: id, answers: request }], comments: comments });
        } catch {
          setUploadErrors(true);
        }
      } else {
        setCurrentIndex(currentIndex + 1);
      }
      setImagesUploading(false);
    }
  };

  const uploadImages = async (answers: IBodyAnswer[]): Promise<void> => {
    for (const answer of answers) {
      // Upload all images, rewrites detail.image with link to uploaded image
      await Promise.all(
        answer.detail.map(async (detail: IDetail) => {
          if (studyPatientId && detail.image.length) {
            detail.image = await uploadImage(studyPatientId, sessionId, detail.image, detail.projection);
          }
        })
      );
    }
  };

  const handleCancelComments = () => {
    setAdditionalCommentsShown(false);
  };

  const handleSaveComments = (comment: string) => {
    if (currentPart) {
      currentPart.comments = comment;
      setAdditionalCommentsShown(false);
    }
  };

  useEffect(() => {
    if (answers) {
      const selectedParts: string[] = answers?.map((answer: IBodyAnswer) => answer.part);
      setBodyParts(selectedParts.map((answer: string) => (isFemale ? femaleAreas[answer] : maleAreas[answer])).flat());
    }
  }, [answers]);

  useEffect(() => {
    if (!currentPart) {
      setCurrentPart(bodyParts[0]);
    }
  }, [bodyParts]);

  useEffect(() => {
    setCurrentPart(bodyParts[currentIndex]);
  }, [currentIndex]);

  return (
    <div className="pain_areas__page">
      {<FormStatus loading={imagesUploading} errors={uploadErrors} message={PAIN_AREAS_PAGE_IMAGE_UPLOAD_ERROR_MESSAGE} />}
      <Spin spinning={imagesUploading}>
        <h1 className="pain_areas__page__header">{currentPart?.name || ''}</h1>
        <Row gutter={[42, 42]} justify="center">
          <Col className="pain_areas__page__drawing">
            {currentPart && (
              <PainAreasDrawing
                currentPart={currentPart}
                setCanvasData={setCanvasData}
                selectedAreas={selectedAreas}
                setSelectedAreas={setSelectedAreas}
                loading={loading}
                setSketchFieldRef={setSketchFieldRef}
              />
            )}
          </Col>
          <Col className="pain_areas__page__areas_list">
            <PainAreasList selectedAreas={selectedAreas} />
          </Col>
        </Row>
        <Row className="mt-8 pain_areas__page__footer">
          <Row justify="space-between" className="pain_areas__page__nav_controls">
            {currentIndex > 0 && (
              <FormButton type="default" onClick={onBackClick}>
                Back
              </FormButton>
            )}
            <FormButton type="default" size="large" onClick={toggleAdditionalComments}>
              Any additional comments?
            </FormButton>
            <FormButton
              type="primary"
              onClick={onNextClick}
              disabled={(currentIndex === bodyParts.length - 1 && !selectedAreas.length) || loading}
            >
              Next
            </FormButton>
          </Row>
          <span className="warning">
            * Please draw all painful areas and identify all painful scars before exiting.
            <br /> Progress can not be saved until this step is complete.
          </span>
        </Row>
        {additionalCommentsShown && (
          <Row>
            <AdditionalComments
              onCancel={handleCancelComments}
              onSave={handleSaveComments}
              value={currentPart?.comments}
              visible={additionalCommentsShown}
            />
          </Row>
        )}
      </Spin>
    </div>
  );
};

export const PainAreasPage = communicationPatientSessions.injector(PainAreas);
