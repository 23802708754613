import { AnyAction, Reducer } from 'redux';
import { RouterState } from 'react-router-redux';
import { communicationPatientSessions } from 'entities/PatientSessions/PatientSessions.communication';

type RoutingReducer = Reducer<RouterState, AnyAction>;

export interface IApplicationState {
  routing?: RoutingReducer | null;
  [key: string]: any;
}

const reducers = {
  ...communicationPatientSessions.reducers,
};

export default reducers;
