import React, { useState } from 'react';
import { Layout, Form, Radio, Space } from 'antd';
import { ManageBtns } from 'common/components/Form/ManageBtns';
import { IBaseParams, IFormValues, IPromisItem, IQuestionnaireAnswer } from 'common/models/formBuilder.models';
import { DiaryNavButtons } from 'common/components/DiaryNavButtons';
import { IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  handleSubmit: (params: IBaseParams) => void;
}

export const PromisPage: React.FC<IComponentProps> = (props) => {
  const { questionnaire, handleSubmit } = props;
  // elements array always has only one object
  const data = questionnaire.elements[0];
  const disclaimer = data.disclaimer;
  const question = data.question.replace(/<\/?[^>]+(>|$)/g, '');
  const [comment, setComment] = useState<string>('');
  const [disableNextButton, setDisableNextButton] = useState<boolean>(!questionnaire.isFinished ? !!question.length : false);
  const [isDiaryPage] = useState<boolean>(questionnaire.textId === 'diary_promis');

  const handleSave = (comment: string) => {
    setComment(comment);
  };

  const onSubmit = (values: IFormValues) => {
    const answers: IQuestionnaireAnswer[] = [];
    const result = data.answers.filter((item: IPromisItem) => Object.values(values).includes(item.value));
    answers.push({
      questionId: Object.keys(values)[0],
      answers: result,
      comment,
    });
    handleSubmit({ elements: answers });
  };

  return (
    <Layout>
      <Form layout="vertical" onFinish={onSubmit} id="form">
        {questionnaire.isFinished ? (
          <span className="promis__finished_text">
            {`You've successfully completed the assessment and cannot change your answers any longer. Please click "Back" to get
            back to the previous forms, or click "Next" to proceed to the Diary options page.`}
          </span>
        ) : (
          <Form.Item
            label={
              <div className="mb-6 promis__label">
                {disclaimer && (
                  <div className="mb-2">
                    <span>{disclaimer}</span>
                    <br />
                  </div>
                )}
                <span>{question}</span>
              </div>
            }
            name={data.textId}
          >
            <Radio.Group onChange={() => setDisableNextButton(false)}>
              <Space direction="vertical">
                {data.answers.map((item: IPromisItem) => {
                  return (
                    <Radio key={item.id} value={item.value}>
                      {item.text}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Form.Item>
        )}
        {!isDiaryPage && <ManageBtns handleSave={handleSave} nextButtonDisabled={disableNextButton} />}
      </Form>
      {isDiaryPage && <DiaryNavButtons disableNextButton={disableNextButton} />}
    </Layout>
  );
};
