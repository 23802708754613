/* eslint-disable @typescript-eslint/no-dynamic-delete */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Modal } from 'antd';
import isEqual from 'lodash.isequal';
import { useForm } from 'antd/lib/form/Form';
import { IBaseParams, IFormValues, IPatientAnswer, IQuestionnaireAnswer } from 'common/models/formBuilder.models';
import { CUSTOM_MEDICATIONS_MAX_COUNT, QUESTIONNAIRE_UPDATE_INTERVAL } from 'common/config';
import { useTimer } from 'common/hooks/useTimer';
import { FormInput } from 'common/components/Form/FormInput';
import { FormSelect } from 'common/components/Form/FormSelect';
import { medicationDoseValidators, medicationTimeTakenValidators, reliefSelectorOptions } from 'common/const/questionnaire.const';
import { ReactComponent as AddIcon } from 'app/assets/images/svg/add-btn.svg';
import { ReactComponent as RemoveIcon } from 'app/assets/images/svg/remove-btn.svg';
import { ReactComponent as Arrow } from 'app/assets/images/svg/arrow.svg';
import { AddMedication } from 'app/pages/Diary/Medications/AddMedication';
import { IQuestionnaireElement, IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  handleSubmit: (params: IBaseParams, update?: boolean) => void;
  patientSessionId: string;
  getPrevPatientSessionsModel: (id: string) => void;
  patientAnswers: IPatientAnswer[];
}

export const DiaryMedicationsMobile: React.FC<IComponentProps> = (props) => {
  const { questionnaire, handleSubmit, patientSessionId, getPrevPatientSessionsModel } = props;
  // True on first visit, further - patientAnswer dependent. Reversed because 1st element specifies answer whether medication wasn't taken
  const [medicationsTaken, setMediationsTaken] = useState<boolean>(!questionnaire.elements[0].patientAnswers);
  const [answers, setAnswers] = useState<IFormValues[]>([]);
  const [answersCopy, setAnswersCopy] = useState<IFormValues[]>([]);
  const [timer, timerLimit, flush] = useTimer(QUESTIONNAIRE_UPDATE_INTERVAL);
  const [medicationToBeRemoved, setMedicationToBeRemoved] = useState<string | null>(null);
  const [medicationToBeAdded, setMedicationToBeAdded] = useState<string | null>(null);
  const [canAddCustomElement, setCanAddCustomElement] = useState<boolean>(true);
  const [formController] = useForm();
  const isFirstAssessment = questionnaire.prevTextId === null;

  useEffect(() => {
    setAnswers(formController.getFieldsValue());

    const customElementsCount = questionnaire.elements.reduce((sum: number, element: IQuestionnaireElement) => {
      !element.isUserDeleted && element.isUserDefined && sum++;
      return sum;
    }, 0);

    setCanAddCustomElement(customElementsCount < CUSTOM_MEDICATIONS_MAX_COUNT);
  }, [questionnaire.elements]);

  useEffect(() => {
    if (!medicationToBeAdded && timer === timerLimit && (!isEqual(answers, answersCopy) || medicationToBeRemoved)) {
      handleSubmit({ elements: generateElementsArray() }, true);
      setAnswersCopy(answers);
      if (medicationToBeRemoved) {
        setMedicationToBeRemoved(null);
      }
    }
  }, [timer]);

  const isDoseEqualsZero = useCallback(
    (id: string) => {
      return formController.getFieldInstance(id)?.input.value === '0';
    },

    [answers]
  );

  const generateElementsArray = () => {
    let elements: IQuestionnaireAnswer[] = [];
    const checkboxElement = questionnaire.elements[0];
    const values = formController.getFieldsValue();
    const fieldsWithErrors = formController
      .getFieldsError()
      .filter((field) => field.errors.length)
      .map((field) => field.name)
      .flat();

    Object.entries(values).forEach((entry: [string, unknown]) => {
      const id = entry[0];
      const element = questionnaire.elements.find((element: IQuestionnaireElement) => element.textId === id);
      const question = element ? element.question : ((entry[1] as IFormValues)[`${id}_question`] as string);
      delete (entry[1] as IFormValues)[`${id}_question`];

      // Remove all fields that fail validation from response object
      Object.keys(entry[1] as string[]).forEach(
        (key: string) => fieldsWithErrors.includes(key) && delete (entry[1] as IFormValues)[key]
      );

      const previouslyDefinedElement = questionnaire.elements.find(
        (element: IQuestionnaireElement) => element.isUserDeleted && element.question === question
      );

      // True in case when the element was previously removed and then added again
      if (previouslyDefinedElement && previouslyDefinedElement?.textId < id) {
        // Remove old value from response object
        elements = elements.filter((element: IQuestionnaireAnswer) => element.question !== question);

        // Append it again with new values and original id
        elements.push({
          questionId: previouslyDefinedElement.textId,
          answers: {
            [`${previouslyDefinedElement.textId}_dose`]: Object.values(entry[1] as Object)[0],
            [`${previouslyDefinedElement.textId}_time`]: Object.values(entry[1] as Object)[1],
            [`${previouslyDefinedElement.textId}_relief`]: Object.values(entry[1] as Object)[2],
          },
          question,
          isUserDeleted: false,
          isUserDefined: true,
        });
      } else {
        elements.push({
          questionId: id,
          answers: entry[1],
          question,
          ...((medicationToBeRemoved === id || element?.isUserDeleted) && { isUserDeleted: true }),
          ...((medicationToBeAdded === id || element?.isUserDefined) && { isUserDefined: true }),
        });
      }
    });

    if (medicationsTaken) {
      elements.push({
        questionId: checkboxElement.textId,
        answers: false,
        question: checkboxElement.question,
      });
    } else {
      elements.push({
        questionId: checkboxElement.textId,
        answers: true,
        question: checkboxElement.question,
      });
    }

    return elements;
  };

  const toggleMedicationsTaken = () => {
    setMediationsTaken(!medicationsTaken);
  };

  const addMedication = () => {
    setMedicationToBeAdded(`diary_medications_list_${questionnaire.elements.length + 2}`);
  };

  const saveMedication = () => {
    if (medicationToBeAdded) {
      formController
        .validateFields([
          [medicationToBeAdded, `${medicationToBeAdded}_question`],
          [medicationToBeAdded, `${medicationToBeAdded}_dose`],
          [medicationToBeAdded, `${medicationToBeAdded}_time`],
          [medicationToBeAdded, `${medicationToBeAdded}_relief`],
        ])
        .then(() => handleSubmit({ elements: generateElementsArray() }, true))
        .then(() => setMedicationToBeAdded(null))
        .catch(() => null);
    }
  };

  const submitData = () => {
    handleSubmit({ elements: generateElementsArray() });
  };

  return (
    <div className="diary_medications">
      <h1 className="layout-mobile__title">Medications</h1>
      <Checkbox
        className="mb-8 diary_medications__checkbox"
        name="medications_was_used"
        onChange={toggleMedicationsTaken}
        defaultChecked={!medicationsTaken}
      >
        Check if you didn&apos;t take
        <br /> any pain medications today
      </Checkbox>
      <Form
        onFinish={submitData}
        onValuesChange={() => setAnswers(formController.getFieldsValue())}
        id="form"
        form={formController}
      >
        {medicationsTaken &&
          questionnaire.elements.slice(1).map((element: IQuestionnaireElement) => {
            return (
              <div className="diary_medications_item" key={element.textId} hidden={element.isUserDeleted}>
                <Form.Item name={[element.textId, `${element.textId}_question`]} initialValue={element.question}>
                  <span className="diary_medications_item__title">{element.question}</span>
                </Form.Item>

                <FormInput
                  name={[element.textId, `${element.textId}_dose`]}
                  placeholder="Dose (s)"
                  type="number"
                  validators={medicationDoseValidators}
                  // trick to avoid validation if medication was deleted by user
                  disabled={element.isUserDeleted}
                  initialValue={element.patientAnswers?.[`${element.textId}_dose`]}
                />
                <FormInput
                  name={[element.textId, `${element.textId}_time`]}
                  placeholder="Time(s) Taken"
                  type="number"
                  validators={medicationTimeTakenValidators}
                  // trick to avoid validation if medication was deleted by user
                  disabled={element.isUserDeleted}
                  initialValue={element.patientAnswers?.[`${element.textId}_time`]}
                />
                <FormSelect
                  name={[element.textId, `${element.textId}_relief`]}
                  options={reliefSelectorOptions}
                  placeholder="Relief"
                  // trick to avoid validation if medication was deleted by user
                  disabled={element.isUserDeleted || isDoseEqualsZero(`${element.textId}_${element.textId}_dose`)}
                />
                <Button className="diary_medications_item__remove" onClick={() => setMedicationToBeRemoved(element.textId)}>
                  {
                    <div className="mr-8 ml-8">
                      <RemoveIcon className="mr-4 " />
                      <span>I don&apos;t take it anymore</span>
                    </div>
                  }
                </Button>
              </div>
            );
          })}
        {medicationToBeAdded !== null && (
          <AddMedication
            id={medicationToBeAdded}
            saveMedication={saveMedication}
            isDoseEqualsZero={isDoseEqualsZero}
            setAddMedicationShown={setMedicationToBeAdded}
            elements={questionnaire.elements}
          />
        )}
      </Form>
      {medicationsTaken && canAddCustomElement && (
        <Button className="layout-mobile__btn diary_medications__add_button" type="ghost" onClick={addMedication}>
          <AddIcon />
          Add medication
        </Button>
      )}
      {!isFirstAssessment && (
        <Button
          className="layout-mobile__btn diary_medications__back_button"
          type="ghost"
          onClick={() => getPrevPatientSessionsModel(patientSessionId)}
        >
          <Arrow />
          Back
        </Button>
      )}
      <Button
        className="layout-mobile__btn"
        type="primary"
        htmlType="submit"
        form="form"
        disabled={medicationsTaken && questionnaire.elements.length <= 1}
      >
        Next
      </Button>
      <Modal
        className="diary_medications__modal"
        visible={medicationToBeRemoved !== null}
        cancelText="No"
        onCancel={() => setMedicationToBeRemoved(null)}
        okText="Yes"
        onOk={() => flush()}
      >
        <span>
          Are you sure you want to remove this medication from the list? If you are still prescribed this medication but
          didn&apos;t take it today, please select &quot;No&quot; and then enter &quot;0&quot; for Time(s) taken and Dose(s)
        </span>
      </Modal>
    </div>
  );
};
