import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { IFormValues } from 'common/models/formBuilder.models';
import { ReactComponent as RemoveIcon } from 'app/assets/images/svg/remove-btn.svg';

interface ITextRepresentationProps {
  element: IFormValues | undefined;
  removeData: (id: string, index: number) => void;
}

export const TextRepresentation: React.FC<ITextRepresentationProps> = (props) => {
  const { element, removeData } = props;

  return element ? (
    <div>
      {Object.values(element)
        .flat()
        .map((entry, index) => {
          const key = Object.keys(element)[0];
          const timeRange = entry[`${key}_time_range`];

          return (
            key &&
            timeRange && (
              <div className="mt-6" key={key + `_${index}`}>
                <h4>Temp: {entry[`${key}_temp`]} ℉</h4>
                <h4>Start Time: {moment(timeRange[0]).format('LT')}</h4>
                <h4>End Time: {moment(timeRange[1]).format('LT')}</h4>
                <h4>Gel: {entry[`${key}_gel`]}</h4>
                <h4>Pain level Start: {entry[`${key}_pain_level_start`]}</h4>
                <h4>Pain level End: {entry[`${key}_pain_level_end`]}</h4>
                <Button onClick={() => removeData(key, index)}>
                  {
                    <div className="mr-8 ml-8">
                      <RemoveIcon className="mr-4 dts__treatment_item__remove" />
                      <span>Remove data</span>
                    </div>
                  }
                </Button>
              </div>
            )
          );
        })}
    </div>
  ) : null;
};
