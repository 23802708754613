/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import { Button, Form, FormInstance, Table, Tooltip } from 'antd';
import { FormInput } from 'common/components/Form/FormInput';
import { FormSelect } from 'common/components/Form/FormSelect';
import {
  medicationDoseValidators,
  medicationTimeTakenValidators,
  painReliefOptions,
  shortAnswer,
  tooltipMessageAffect,
  tooltipMessageDoze,
  tooltipMessageRelief,
  tooltipMessageTime,
} from 'common/const/questionnaire.const';
import { IFormValues, IPatientAnswer, IQuestionnaireData, IValueChangeType } from 'common/models/formBuilder.models';
import { AddOptionBtn } from 'common/components/Form/AddOptionBtn';
import { ManageBtns } from 'common/components/Form/ManageBtns';
import { CUSTOM_MEDICATIONS_MAX_COUNT } from 'common/config';
import { ReactComponent as AddIcon } from 'app/assets/images/svg/add-btn.svg';
import { IQuestionnaireElement, IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  name: string;
  data: IQuestionnaireData[];
  onSubmit: (values: IFormValues) => void;
  handleSave: (comment: string) => void;
  handleChange: (values: IFormValues, formController: FormInstance<IFormValues>, type?: IValueChangeType) => void;
  patientAnswers?: IPatientAnswer[];
}

export const MedicationsListPage: React.FC<IComponentProps> = (props) => {
  const { name, onSubmit, handleSave, questionnaire, patientAnswers, handleChange } = props;
  const [tableData, setTableData] = useState<IQuestionnaireData[] | []>([]);
  const [formController] = Form.useForm<IFormValues>();
  const [canAddCustomElement, setCanAddCustomElement] = useState<boolean>(true);

  useEffect(() => {
    if (questionnaire.elements) {
      const data: IQuestionnaireData[] = questionnaire.elements.map((element: IQuestionnaireElement, index: number) => {
        return {
          key: index,
          medication: element.question,
          item: element.textId,
          isUserDeleted: element.isUserDeleted,
        };
      });
      setTableData(data);
      // If any fields have been pre-filled, writes its values to the answers object
      const prefilledAnswers: IFormValues = {};
      questionnaire.elements.forEach((element: IQuestionnaireElement) => {
        prefilledAnswers[element.textId] = element.patientAnswers;
      });
      handleChange(prefilledAnswers, formController);
      // ===========================================================================
    }

    const customElementsCount = questionnaire.elements.reduce((sum: number, element: IQuestionnaireElement) => {
      element.isUserDefined && sum++;
      return sum;
    }, 0);

    setCanAddCustomElement(customElementsCount < CUSTOM_MEDICATIONS_MAX_COUNT);
  }, [questionnaire.elements]);

  const columns = [
    {
      title: 'Medication',
      render: (key: IQuestionnaireData) => (
        <Form.Item key={key.key} name={[key.item, `${key.item}_question`]} initialValue={key.medication}>
          <span>{key.medication}</span>
        </Form.Item>
      ),
    },
    {
      title: () => (
        <div className="tooltip-container">
          <span>Dose(s)</span>
          <Tooltip className="tooltip" title={tooltipMessageDoze} />
        </div>
      ),
      render: (key: IQuestionnaireData) => (
        <FormInput
          key={key.key}
          name={[key.item, `${key.item}_dose`]}
          placeholder="10"
          type="number"
          validators={medicationDoseValidators}
          disabled={key.isUserDeleted}
        />
      ),
    },
    {
      title: () => (
        <div className="tooltip-container">
          <span>Time(s) Taken</span>
          <Tooltip className="tooltip" title={tooltipMessageTime} />
        </div>
      ),
      render: (key: IQuestionnaireData) => (
        <FormInput
          key={key.key}
          name={[key.item, `${key.item}_time`]}
          placeholder="0"
          type="number"
          validators={medicationTimeTakenValidators}
          disabled={key.isUserDeleted}
        />
      ),
    },
    {
      title: () => (
        <div className="tooltip-container">
          <span>Pain relief</span>
          <Tooltip
            className="tooltip"
            title={tooltipMessageRelief.split('\n').map((line: string, index: number) => (
              <p key={index}>{line}</p>
            ))}
          />
        </div>
      ),
      render: (key: IQuestionnaireData) => (
        <FormSelect
          key={key.key}
          className="form__select__pain_relief"
          name={[key.item, `${key.item}_relief`]}
          options={painReliefOptions}
          placeholder={painReliefOptions[0]}
          disabled={key.isUserDeleted}
        />
      ),
    },
    {
      title: () => (
        <div className="tooltip-container">
          <span>If this treatment is successful, would you expect to need less of this medication?</span>
          <Tooltip className="tooltip" title={tooltipMessageAffect} />
        </div>
      ),
      render: (key: IQuestionnaireData) => (
        <div key={key.key} className="sym-med-list-page__treatment">
          <FormSelect
            className="sym-med-list-page__select"
            options={shortAnswer}
            name={[key.item, `${key.item}_affect`]}
            placeholder="Yes"
            disabled={key.isUserDeleted}
          />
          {key.isUserDeleted ? (
            <Button onClick={() => returnMedication(key.key)}>
              <AddIcon />
            </Button>
          ) : (
            <Button onClick={() => removeMedication(key.key)}>
              <div className="sym-med-list-page__remove-btn"></div>
            </Button>
          )}
        </div>
      ),
    },
  ];

  const addMedication = (_: string, value: string) => {
    // Prevents duplicating items and related errors
    const isExists = tableData.find((item: IQuestionnaireData) => item.medication === value);
    const index = tableData.length ? tableData[tableData.length - 1].key : 0;
    const newItemId = `${questionnaire.textId}_${index + 2}`;
    if (!isExists) {
      handleChange(formController.getFieldsValue(), formController, {
        userDefined: { [newItemId]: value },
      });
    }
  };

  const removeMedication = (key: number) => {
    const removedItem: string | undefined = tableData.find((item: IQuestionnaireData) => item.key === key)?.item;
    if (removedItem) {
      handleChange(formController.getFieldsValue(), formController, { deleted: removedItem });
    }
  };

  const returnMedication = (key: number) => {
    const returnedItem: string | undefined = tableData.find((item: IQuestionnaireData) => item.key === key)?.item;
    if (returnedItem) {
      handleChange(formController.getFieldsValue(), formController, { returned: returnedItem });
    }
  };

  const handleOnValuesChanged = (_: any, values: IFormValues) => {
    handleChange(values, formController);
  };

  return (
    <Form
      onFinish={onSubmit}
      fields={patientAnswers}
      initialValues={patientAnswers}
      form={formController}
      onValuesChange={handleOnValuesChanged}
    >
      <h1 className="form__title">{name}</h1>
      <p className="form__description">
        Please answer these questions for a typical day so we can tell if therapy is working for you.
      </p>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        rowClassName={(record) => (record.isUserDeleted ? 'ant-table-row--opaque' : '')}
      />
      {canAddCustomElement && (
        <div className="sym-med-list-page__add-btn">
          <AddOptionBtn name="Add medication" question="Medications list" onChange={addMedication} />
        </div>
      )}
      <ManageBtns handleSave={handleSave} />
    </Form>
  );
};
