import { PAIN_AREAS_PAGE_CANVAS_HEIGHT, PAIN_AREAS_PAGE_CANVAS_WIDTH } from 'common/config';

export const mergeBase64Images = async (images: string[]): Promise<string> => {
  // Wait for all images to be loaded
  return Promise.all(
    images.map((image: string) => {
      return new Promise((resolve: (value: CanvasImageSource | PromiseLike<CanvasImageSource>) => void) => {
        const img: HTMLImageElement = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
          resolve(img);
        };
        img.src = image;
      });
    })
  ).then((images: CanvasImageSource[]) => {
    const canvas = document.createElement('canvas');
    canvas.width = PAIN_AREAS_PAGE_CANVAS_WIDTH;
    canvas.height = PAIN_AREAS_PAGE_CANVAS_HEIGHT;

    const ctx = canvas.getContext('2d');
    if (ctx) {
      // Add all loaded images on canvas
      images.forEach((image: CanvasImageSource) => {
        ctx.drawImage(image, 0, 0);
      });
    }
    // Return base64 representation of canvas
    return canvas.toDataURL('image/jpeg');
  });
};
