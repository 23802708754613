import { Button, Row } from 'antd';
import React from 'react';
import { ReactComponent as Bucket } from 'app/assets/images/svg/canvasControls/bucket.svg';
import { ReactComponent as Pencil } from 'app/assets/images/svg/canvasControls/pencil.svg';
import { ReactComponent as Undo } from 'app/assets/images/svg/canvasControls/undo.svg';
import { ReactComponent as Plus } from 'app/assets/images/svg/canvasControls/plus.svg';
import { ReactComponent as Eye } from 'app/assets/images/svg/canvasControls/eye.svg';

interface IComponentProps {
  onClick?: () => void;
  transparent?: boolean;
  border?: 'round' | 'semicircular';
  icon?: 'bucket' | 'pencil' | 'undo' | 'plus' | 'eye';
  disabled?: boolean;
}

export const CanvasControlButton: React.FC<IComponentProps> = (props) => {
  const { children, onClick, icon, transparent = false, border, disabled } = props;

  const iconSwitch = () => {
    switch (icon) {
      case 'bucket':
        return <Bucket />;
      case 'pencil':
        return <Pencil />;
      case 'undo':
        return <Undo />;
      case 'plus':
        return <Plus />;
      case 'eye':
        return <Eye />;
      default:
        return;
    }
  };

  const setClasses = () => {
    let classname = 'canvas__control__button';

    switch (border) {
      case 'round':
        classname += ' canvas__control__button--round';
        break;
      case 'semicircular':
        classname += ' canvas__control__button--semicircular';
        break;
    }

    if (transparent) {
      classname += ' canvas__control__button--transparent';
    }

    if (disabled) {
      classname += ' canvas__control__button--disabled ';
    }

    return classname;
  };

  return (
    <Button className={setClasses()} onClick={onClick}>
      <Row align="middle" justify="space-between">
        {iconSwitch()}
        {children && <span>{children}</span>}
      </Row>
    </Button>
  );
};
