import React from 'react';
import { Checkbox, Form } from 'antd';
import { FormInstance, Rule } from 'antd/lib/form';
import {
  baseValidationRules,
  noneValueForSymptomsQuestion,
  nonHeadSymptomsWithNoneOption,
} from 'common/const/questionnaire.const';
import { IFormValues } from 'common/models/formBuilder.models';

interface IComponentProps {
  name: string;
  label?: string;
  checkboxButtons: string[];
  className?: string;
  additionalCheckboxValues?: string[];
  maximumChecked?: number;
  checked?: string[];
  rules?: Rule[];
  required?: boolean;
  formController: FormInstance<IFormValues>;
}

export const FormCheckbox: React.FC<IComponentProps> = (props) => {
  const {
    formController,
    name,
    label,
    checkboxButtons,
    className,
    maximumChecked,
    checked = [],
    additionalCheckboxValues,
    rules,
    required = true,
  } = props;
  // Task TMQ-979
  let disabled = false;
  // --> Here we check that the control has custom behavior
  if (nonHeadSymptomsWithNoneOption.includes(name)) {
    const checked = formController.getFieldValue(name);

    if (checked && checked.includes(noneValueForSymptomsQuestion)) {
      disabled = true;
    }
  }
  // <--

  return (
    <Form.Item
      label={label}
      name={name}
      rules={required ? rules || baseValidationRules() : undefined}
      initialValue={additionalCheckboxValues}
      required={required}
    >
      <Checkbox.Group className={className}>
        {checkboxButtons.map((item) => {
          const itemDisabled = maximumChecked ? checked.length >= maximumChecked && !checked.includes(item) : false;

          return (
            <Checkbox key={item} value={item} disabled={(item !== noneValueForSymptomsQuestion && disabled) || itemDisabled}>
              {item}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
    </Form.Item>
  );
};
