import React from 'react';
import { IBodyParts } from 'common/models/body.models';
import MaleHeadOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleHeadOutlined.png';
import MaleChestOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleChestOutlined.png';
import MaleLegsOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleLegsOutlined.png';
import MaleFeetOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleFeetOutlined.png';
import MaleLeftArmOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleLeftArmOutlined.png';
import MaleRightArmOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleRightArmOutlined.png';
import MaleLeftHandOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleLeftHandOutlined.png';
import MaleRightHandOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleRightHandOutlined.png';
import FemaleHeadOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleHeadOutlined.png';
import FemaleChestOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleChestOutlined.png';
import FemaleLegsOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleLegsOutlined.png';
import FemaleFeetOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleFeetOutlined.png';
import FemaleLeftArmOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleLeftArmOutlined.png';
import FemaleRightArmOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleRightArmOutlined.png';
import FemaleLeftHandOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleLeftHandOutlined.png';
import FemaleRightHandOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleRightHandOutlined.png';

export enum ECommonBodyParts {
  HeadNeck = 'head_neck',
  RightArm = 'right_arm',
  LeftArm = 'left_arm',
  RightHand = 'right_hand',
  LeftHand = 'left_hand',
  ChestAbdomenBack = 'chest_abdomen_back',
  Legs = 'legs',
  Feet = 'feet',
}

export const body: IBodyParts = {
  [ECommonBodyParts.HeadNeck]: {
    title: 'Head / Neck',
    value: ECommonBodyParts.HeadNeck,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleHeadOutlined}
        key={ECommonBodyParts.HeadNeck}
        className="body_areas__page__body__part body_areas__page__body__head"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleHeadOutlined}
        key={ECommonBodyParts.HeadNeck}
        className="body_areas__page__body__part body_areas__page__body__head--female"
      />
    ),
  },
  [ECommonBodyParts.RightArm]: {
    title: 'Right Arm',
    value: ECommonBodyParts.RightArm,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleRightArmOutlined}
        key={ECommonBodyParts.RightArm}
        className="body_areas__page__body__part body_areas__page__body__right_arm"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleRightArmOutlined}
        key={ECommonBodyParts.RightArm}
        className="body_areas__page__body__part body_areas__page__body__right_arm--female"
      />
    ),
  },
  [ECommonBodyParts.LeftArm]: {
    title: 'Left Arm',
    value: ECommonBodyParts.LeftArm,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleLeftArmOutlined}
        key={ECommonBodyParts.LeftArm}
        className="body_areas__page__body__part body_areas__page__body__left_arm"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleLeftArmOutlined}
        key={ECommonBodyParts.LeftArm}
        className="body_areas__page__body__part body_areas__page__body__left_arm--female"
      />
    ),
  },
  [ECommonBodyParts.RightHand]: {
    title: 'Right Hand',
    value: ECommonBodyParts.RightHand,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleRightHandOutlined}
        key={ECommonBodyParts.RightHand}
        className="body_areas__page__body__part body_areas__page__body__right_hand"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleRightHandOutlined}
        key={ECommonBodyParts.RightHand}
        className="body_areas__page__body__part body_areas__page__body__right_hand--female"
      />
    ),
  },
  [ECommonBodyParts.LeftHand]: {
    title: 'Left Hand',
    value: ECommonBodyParts.LeftHand,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleLeftHandOutlined}
        key={ECommonBodyParts.LeftHand}
        className="body_areas__page__body__part body_areas__page__body__left_hand"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleLeftHandOutlined}
        key={ECommonBodyParts.LeftHand}
        className="body_areas__page__body__part body_areas__page__body__left_hand--female"
      />
    ),
  },
  [ECommonBodyParts.ChestAbdomenBack]: {
    title: 'Chest/Abdomen/Back',
    value: ECommonBodyParts.ChestAbdomenBack,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleChestOutlined}
        key={ECommonBodyParts.ChestAbdomenBack}
        className="body_areas__page__body__part body_areas__page__body__chest"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleChestOutlined}
        key={ECommonBodyParts.ChestAbdomenBack}
        className="body_areas__page__body__part body_areas__page__body__chest--female"
      />
    ),
  },
  [ECommonBodyParts.Legs]: {
    title: 'Legs',
    value: ECommonBodyParts.Legs,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleLegsOutlined}
        key={ECommonBodyParts.Legs}
        className="body_areas__page__body__part body_areas__page__body__legs"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleLegsOutlined}
        key={ECommonBodyParts.Legs}
        className="body_areas__page__body__part body_areas__page__body__legs--female"
      />
    ),
  },
  [ECommonBodyParts.Feet]: {
    title: 'Feet',
    value: ECommonBodyParts.Feet,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleFeetOutlined}
        key={ECommonBodyParts.Feet}
        className="body_areas__page__body__part body_areas__page__body__feet"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleFeetOutlined}
        key={ECommonBodyParts.Feet}
        className="body_areas__page__body__part body_areas__page__body__feet--female"
      />
    ),
  },
};
