import { Button } from 'antd';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IPathParam } from 'common/models/formBuilder.models';
import { ReactComponent as Arrow } from 'app/assets/images/svg/arrow.svg';
import {
  communicationPatientSessions,
  IPatientSessionsConnectedProps,
} from 'entities/PatientSessions/PatientSessions.communication';

interface IComponentProps {
  disableNextButton?: boolean;
}

type AllProps = IPatientSessionsConnectedProps & RouteComponentProps<IPathParam> & IComponentProps;

const ManageButtons: React.FC<AllProps> = (props) => {
  const { getPrevPatientSessionsModel, match, disableNextButton } = props;
  const patientSessionId = match.params.id;

  return (
    <>
      <Button className="diary-nav-btn" type="ghost" onClick={() => getPrevPatientSessionsModel(patientSessionId)}>
        <Arrow className="mr-4" />
        Back
      </Button>
      <Button type="primary" form="form" htmlType="submit" disabled={disableNextButton}>
        Next
      </Button>
    </>
  );
};

export const DiaryNavButtons = withRouter(communicationPatientSessions.injector(ManageButtons));
