import React from 'react';
import { Badge } from 'common/components/Body/Badge';
import { IPainArea } from 'common/models/body.models';

interface IComponentProps {
  selectedAreas: IPainArea[];
}

export const PainAreasList: React.FC<IComponentProps> = (props) => {
  const { selectedAreas } = props;

  return (
    <>
      {!selectedAreas.length && (
        <h1 className="placeholder">
          To track your pain levels, we need to know where you have pain. Please draw in where you occasionally have severe pain,
          and also add any scars in the area.{' '}
        </h1>
      )}
      {selectedAreas.length > 0 && (
        <div className="list">
          <div className="list__header">
            <span className="list__header__areas">Pain Areas</span>
            <div className="list__header__types">
              <span>Pain</span>
              <span>Scar</span>
            </div>
          </div>
          {selectedAreas.map((item: IPainArea) => {
            return (
              <div className="list__item" key={item.name}>
                <span className="list__item__area">{item.name}</span>
                <div className="list__item__badges">
                  <Badge active={item.painMarksCount > 0}>Pain</Badge>
                  <Badge active={item.scarMarksCount > 0}>Scar</Badge>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
