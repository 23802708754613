import React, { useEffect, useRef } from 'react';
import { Form, FormInstance } from 'antd';
import { PopupMobile } from 'common/components/PopupMobile';
import { FormInput } from 'common/components/Form/FormInput';
import { FormRadio } from 'common/components/Form/FormRadio';
import { IFormValues } from 'common/models/formBuilder.models';
import { baseValidationRules, textValidationRules } from 'common/const/questionnaire.const';
import { FormSelectSingle } from 'common/components/Form/FormSelectSingle';
import { generateFormItemClassName } from 'common/utils/generateFormItemClassName';
import { compareFields } from 'common/utils/compareFields';
import { IQuestionnaireElement } from 'entities/PatientSessions/PatientSession.models';

export const getFormControl = (
  item: IQuestionnaireElement,
  elements: IQuestionnaireElement[],
  isHeadNonHeadQuestionnaire: boolean
): JSX.Element => {
  switch (item.type) {
    case 'text': {
      return (
        <FormInput
          name={item.textId}
          placeholder={item.question}
          label={item.question}
          type="text"
          rules={item.required === false ? undefined : textValidationRules()}
        />
      );
    }
    case 'radio': {
      return (
        <FormRadio
          className={generateFormItemClassName(item.textId, item.type)}
          name={item.textId}
          label={item.question}
          radioButtons={item.answers}
          tooltip={item.hover}
        />
      );
    }
    case 'number': {
      return (
        <FormInput
          name={item.textId}
          type="number"
          placeholder={item.placeholder}
          label={item.question}
          rules={!item.validators ? baseValidationRules() : undefined}
          validators={item.validators}
        />
      );
    }
    case 'group_number': {
      return (
        <>
          <FormInput
            name={[item.textId, `${item.textId}_max`]}
            type="number"
            label={item.question}
            placeholder="Maximum pain"
            suffix="/10"
            rules={!item.validators ? baseValidationRules() : undefined}
            validators={item.validators}
          />
          <FormInput
            name={[item.textId, `${item.textId}_avg`]}
            type="number"
            placeholder="Average pain"
            suffix="/10"
            rules={!item.validators ? baseValidationRules() : undefined}
            validators={item.validators}
          />
        </>
      );
    }
    case 'select': {
      const element: IQuestionnaireElement =
        elements[elements.findIndex((element: IQuestionnaireElement) => element.textId === item.textId) - 1];
      const isFirstElement: boolean = element.type !== 'select';

      return isHeadNonHeadQuestionnaire ? (
        <Form.Item className="form__diary__select__group mb-4" label={isFirstElement && 'Symptom Severity'} required>
          <FormSelectSingle
            className="form__diary__select__group__item"
            name={item.textId}
            options={item.answers}
            label={item.question}
            placeholder="None"
          />
        </Form.Item>
      ) : (
        <FormSelectSingle name={item.textId} options={item.answers} label={item.question} placeholder={item.placeholder} />
      );
    }
    default:
      return <div></div>;
  }
};

export const renderFormControls = (
  elements: IQuestionnaireElement[],
  fieldsValue: FormInstance<IFormValues[]>,
  isHeadNonHeadQuestionnaire: boolean
): React.ReactNode => {
  return elements.map((item) => {
    switch (true) {
      case !!item.trigger: {
        if (item.trigger?.compare && typeof item.trigger.value === 'number') {
          if (
            compareFields(item.trigger.compare, item.trigger.value, fieldsValue.getFieldValue(item.trigger?.questionId as string))
          ) {
            return <div key={item.textId}>{getFormControl(item, elements, isHeadNonHeadQuestionnaire)}</div>;
          }
        } else if (item.trigger?.value instanceof Array) {
          const isTriggered = item.trigger?.value.includes(fieldsValue.getFieldValue(item.trigger?.questionId as string));
          return isTriggered && <div key={item.textId}>{getFormControl(item, elements, isHeadNonHeadQuestionnaire)}</div>;
        }
        return null;
      }
      case item.type === 'subhead': {
        const labelRef = useRef<HTMLSpanElement>(null);

        useEffect(() => {
          if (item.question && labelRef.current) {
            labelRef.current.innerHTML = item.question;
          }
        }, [item.question]);

        return (
          <div key={item.textId} className="form__subhead mb-8">
            <span ref={labelRef}>{item.question}</span>
          </div>
        );
      }
      case item.type === 'tip': {
        const labelRef = useRef<HTMLSpanElement>(null);

        // eslint-disable-next-line sonarjs/no-identical-functions
        useEffect(() => {
          if (item.question && labelRef.current) {
            labelRef.current.innerHTML = item.question;
          }
        }, [item.question]);

        return (
          <div key={item.textId} className="form__tip mb-8">
            <span ref={labelRef}>{item.question}</span>
            {item.textId.startsWith('diary_dvprs') && <PopupMobile />}
          </div>
        );
      }
      default: {
        return <div key={item.textId}>{getFormControl(item, elements, isHeadNonHeadQuestionnaire)}</div>;
      }
    }
  });
};
