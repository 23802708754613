import React, { useEffect, useRef } from 'react';
import { Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import { RuleObject } from 'rc-field-form/lib/interface';
import { basicRequiredMessage } from 'common/const/questionnaire.const';
import { IQuestionnaireValidator } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  name: string | string[];
  placeholder?: string;
  label?: string;
  suffix?: string;
  type?: 'number' | 'text';
  rules?: Rule[];
  validators?: IQuestionnaireValidator[];
  disabled?: boolean;
  preserve?: boolean;
  initialValue?: string;
}

export const FormInput: React.FC<IComponentProps> = (props) => {
  const { name, label, placeholder, suffix, type, rules, validators, disabled, preserve, initialValue } = props;
  const labelRef = useRef<HTMLSpanElement>(null);

  /* TODO:  
  Proof: https://ant.design/components/form/#Rule (validator)
  Example: https://codesandbox.io/s/yndz1?file=/index.js (line 332)
 */

  const numericalInputValidator = (rule: Pick<RuleObject, 'max' | 'min' | 'message'>, value: number) => {
    if (typeof rule.min === 'number' && value >= rule.min) {
      return Promise.resolve();
    }

    if (typeof rule.max === 'number' && value <= rule.max) {
      return Promise.resolve();
    }

    return Promise.reject(rule.message);
  };

  const generateValidationRules = (): Rule[] => {
    const rules: Rule[] = [{ required: true, message: basicRequiredMessage }];

    validators?.forEach((validator: IQuestionnaireValidator) =>
      rules.push({
        type: type === 'text' ? 'string' : 'number',
        [validator.type]: validator.value,
        message: validator.message,
        ...(type === 'number' && { transform: (value: string) => parseInt(value) }),
        ...(type === 'number' && { validator: numericalInputValidator }),
      })
    );
    return rules;
  };

  useEffect(() => {
    if (label && labelRef.current) {
      labelRef.current.innerHTML = label;
    }
  }, [label]);

  return (
    <Form.Item
      label={label && <span ref={labelRef}>{label}</span>}
      name={name}
      rules={!disabled && validators ? generateValidationRules() : rules}
      preserve={preserve}
      validateFirst
      initialValue={initialValue}
    >
      <Input type={type} placeholder={placeholder} suffix={suffix} disabled={disabled} />
    </Form.Item>
  );
};
