import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { envConfig } from 'common/config';
import 'app/assets/sass/index.scss';
import createStore from 'app/store/createStore';
import App from 'app/pages/App';

// Axios initialization

axios.defaults.baseURL = `/api`;
axios.interceptors.response.use((response) => response.data);

// Render Setup

const MOUNT_NODE = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState = (window as any).___INITIAL_STATE__;
const store = createStore(initialState);

// Sentry initialization
Sentry.init({
  dsn: envConfig.SENTRY_DSN,
  environment: envConfig.NODE_ENV,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

// @ts-ignore
window.checkSentry = (text?: string) => {
  const msg = `Sentry successfully connected. Config: ${JSON.stringify(envConfig)} ${new Date()}. Test string: ${text}`;
  console.log('Go to slack channel and check an error with message: ');
  console.log(msg);
  Sentry.captureException(msg);
};
// //////////////////////////////////////

ReactDOM.render(<App store={store} />, MOUNT_NODE);
