import React, { useState } from 'react';
import { Modal } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { FormSelectSingle } from 'common/components/Form/FormSelectSingle';

interface IComponentProps {
  placeholder?: string;
  className?: string;
  onCancel: () => void;
  onSave: () => void;
  value?: string;
  visible: boolean;
  questionnareTitle: string;
  options: string[];
  handlePrefill: (value: string) => void;
}

export const CopyAnswers: React.FC<IComponentProps> = (props) => {
  const { onCancel, visible, questionnareTitle, options, handlePrefill } = props;
  const [selectedValue, setSelectedValue] = useState<string>();

  const handleChange = (value: SelectValue) => {
    setSelectedValue(value as string);
  };

  return (
    <Modal
      className="copy_answers"
      visible={visible}
      okText="Prefill"
      okButtonProps={{ disabled: !selectedValue }}
      onCancel={onCancel}
      onOk={() => selectedValue && handlePrefill(selectedValue)}
      centered
      closable={false}
      title={`You can prefill answers for the ${questionnareTitle} questionnaire from one of the submitted previously`}
    >
      <FormSelectSingle label="Questionnare" options={options} handleChange={handleChange} />
    </Modal>
  );
};
