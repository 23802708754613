export const envConfig = (process.env.NODE_ENV === 'development' ? process.env : (window as any).env) as {
  SENTRY_DSN: string;
  NODE_ENV: string;
};

export const QUESTIONNAIRE_UPDATE_INTERVAL = 15000;
export const STATUS_BAR_HIDE_DELAY = 3000;
export const DTS_ANSWERS_LIMIT = 30;
export const PAIN_AREAS_PAGE_CANVAS_WIDTH = 550;
export const PAIN_AREAS_PAGE_CANVAS_HEIGHT = 700;
export const PAIN_AREAS_DRAWING_COMPONENT_SELECT_TOOL_BORDER_COLOR = 'blue';
export const PAIN_AREAS_DRAWING_COMPONENT_LINE_COLOR = 'red';
export const PAIN_AREAS_PAGE_IMAGE_UPLOAD_ERROR_MESSAGE = 'Saving failed. Please check your connection and reload this tab';
export const CUSTOM_MEDICATIONS_MAX_COUNT = 10;
export const FORMS_CUSTOM_ITEMS_MAX_COUNT = 25;
