/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import { Form, Table, Tooltip } from 'antd';
import { FormInstance, useForm } from 'antd/lib/form/Form';
import { FormInput } from 'common/components/Form/FormInput';
import { IFormValues, IPatientAnswer, IQuestionnaireData } from 'common/models/formBuilder.models';
import { ManageBtns } from 'common/components/Form/ManageBtns';
import { IQuestionnaireElement, IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  name: string;
  data: IQuestionnaireData[];
  onSubmit: () => void;
  handleSave: (comment: string) => void;
  handleChange: (values: IFormValues, formController: FormInstance<IFormValues>) => void;
  patientAnswers?: IPatientAnswer[];
}

export const PretherapyTPLELT: React.FC<IComponentProps> = (props) => {
  const { name, onSubmit, handleSave, questionnaire, patientAnswers, handleChange } = props;
  const [tableData, setTableData] = useState<IQuestionnaireData[] | []>([]);
  const [formController] = useForm<IFormValues>();

  useEffect(() => {
    if (questionnaire.elements) {
      const data: IQuestionnaireData[] = questionnaire.elements
        .slice(1, questionnaire.elements.length)
        .map((element: IQuestionnaireElement, index: number) => ({
          key: index,
          pain_area: element.question,
          item: element.textId,
        }));
      setTableData(data);
    }

    // If any fields have been pre-filled, writes its values to the answers object
    const prefilledAnswers: IFormValues = {};
    questionnaire.elements.forEach((element: IQuestionnaireElement) => {
      prefilledAnswers[element.textId] = element.patientAnswers;
    });
    handleChange(prefilledAnswers, formController);
    // ===========================================================================
  }, [questionnaire.elements]);

  const handleOnValuesChanged = (_: any, values: IFormValues) => {
    handleChange(values, formController);
  };

  const columns = [
    {
      title: 'Pain Areas',
      dataIndex: 'pain_area',
    },
    {
      title: () => (
        <div className="tooltip-container">
          <span>Pain level right now</span>
          <Tooltip className="tooltip" title={<p>Please enter your pain levels right now to give us a baseline for today.</p>} />
        </div>
      ),
      render: (key: IQuestionnaireData) => (
        <FormInput
          key={key.key}
          name={[key.item, `${key.item}_maximum_pain`]}
          placeholder="0"
          type="number"
          suffix="/10"
          validators={questionnaire.elements.find((element: IQuestionnaireElement) => element.textId === key.item)?.validators}
        />
      ),
    },
    {
      title: () => (
        <div className="tooltip-container">
          <span>Typical pain level at bedtime</span>
          <Tooltip
            className="tooltip"
            title={
              <p>Please enter the pain levels you would expect at bedtime to help us see if the treatment is helping you.</p>
            }
          />
        </div>
      ),
      render: (key: IQuestionnaireData) => (
        <FormInput
          key={key.key}
          name={[key.item, `${key.item}_average_pain`]}
          placeholder="0"
          type="number"
          suffix="/10"
          validators={questionnaire.elements.find((element: IQuestionnaireElement) => element.textId === key.item)?.validators}
        />
      ),
    },
  ];

  return (
    <Form onFinish={onSubmit} fields={patientAnswers} form={formController} onValuesChange={handleOnValuesChanged}>
      <h1 className="form__title">{name}</h1>
      <p className="form__description">
        Please describe how your pain would normally vary throughout the rest of the day from where it is now
      </p>
      <div className="pretherapy-tplelt__container">
        <div>
          <FormInput
            name={questionnaire.elements[0].textId}
            placeholder={questionnaire.elements[0].placeholder}
            validators={questionnaire.elements[0].validators}
            type="text"
          />
          <Table columns={columns} dataSource={tableData} pagination={false} />
        </div>
      </div>
      <div className="pretherapy-tplelt__btns">
        <ManageBtns handleSave={handleSave} />
      </div>
    </Form>
  );
};
