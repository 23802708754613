import React from 'react';
import { Button, Modal, Row } from 'antd';
import { Rule, RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { FormInput } from 'common/components/Form/FormInput';
import { FormSelect } from 'common/components/Form/FormSelect';
import {
  basicRequiredMessage,
  medicationDoseValidators,
  numericFieldsValidators,
  reliefSelectorOptions,
} from 'common/const/questionnaire.const';
import { IQuestionnaireElement } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  id: string;
  saveMedication: () => void;
  isDoseEqualsZero: (id: string) => boolean;
  setAddMedicationShown: (state: string | null) => void;
  elements: IQuestionnaireElement[];
}

export const AddMedication: React.FC<IComponentProps> = (props) => {
  const { id, saveMedication, isDoseEqualsZero, setAddMedicationShown, elements } = props;

  const modalFooter = (
    <Row justify="space-between">
      <Button type="ghost" onClick={() => setAddMedicationShown(null)}>
        Cancel
      </Button>
      <Button type="primary" onClick={saveMedication}>
        Save
      </Button>
    </Row>
  );

  const validateUniqueMedicationName = (_: RuleObject, value: StoreValue) => {
    let isFieldValidating = true;

    for (const element of elements) {
      if (!element.isUserDeleted && element.question.toLowerCase() === value.toLowerCase()) {
        isFieldValidating = false;
        break;
      }
    }

    return isFieldValidating ? Promise.resolve() : Promise.reject();
  };

  const uniqueMedicationNameValidator: Rule[] = [
    { required: true, message: basicRequiredMessage },
    { validator: validateUniqueMedicationName, message: 'Medication already exists' },
  ];

  return (
    <Modal footer={modalFooter} closable={false} visible>
      <FormInput
        name={[id, `${id}_question`]}
        placeholder="Medication"
        type="text"
        rules={uniqueMedicationNameValidator}
        preserve={false}
      />
      <FormInput
        name={[id, `${id}_dose`]}
        placeholder="Dose (s)"
        type="number"
        validators={medicationDoseValidators}
        preserve={false}
      />
      <FormInput
        name={[id, `${id}_time`]}
        placeholder="Time(s) Taken"
        type="number"
        validators={numericFieldsValidators}
        preserve={false}
      />
      <FormSelect
        name={[id, `${id}_relief`]}
        options={reliefSelectorOptions}
        placeholder="Relief"
        disabled={isDoseEqualsZero(`${id}_${id}_dose`)}
        preserve={false}
      />
    </Modal>
  );
};
