import React from 'react';
import { Form, TimePicker } from 'antd';
import moment from 'moment';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { basicRequiredMessage } from 'common/const/questionnaire.const';

interface IComponentProps {
  name: string | string[];
  label?: string;
  placeholder?: string;
  className?: string;
}

export const FormTimePicker: React.FC<IComponentProps> = (props) => {
  const { name, label, className } = props;
  // Required since we use initialValue. The required prop won't work in case if at least 1 of 2 values is selected;
  const validateRangeField = (_: RuleObject, value: StoreValue) => {
    if (!value) {
      return Promise.reject();
    }

    return value[0] !== null && value[1] !== null ? Promise.resolve() : Promise.reject();
  };
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[{ validator: validateRangeField, message: basicRequiredMessage }]}
      initialValue={[moment(), null]}
    >
      <TimePicker.RangePicker className={className} format="hh:mm A" suffixIcon={null} showNow={false} order={false} use12Hours />
    </Form.Item>
  );
};
