import React, { useEffect, useState } from 'react';
import { Layout, Spin } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';
import { getQuestionnaire } from 'common/helpers/switcher.helper';
import { IPatientAnswer, ISelectGroupAnswer, IBaseParams, IPathParam } from 'common/models/formBuilder.models';
import { STATUS_BAR_HIDE_DELAY } from 'common/config';
import { answerIsObject } from 'common/utils/answerIsObject';
import {
  communicationPatientSessions,
  IPatientSessionsConnectedProps,
} from 'entities/PatientSessions/PatientSessions.communication';
import { IQuestionnaireElement, IQuestionnaireComment } from 'entities/PatientSessions/PatientSession.models';

type AllProps = IPatientSessionsConnectedProps & RouteComponentProps<IPathParam>;

const SwitcherComponent: React.FC<AllProps> = (props) => {
  const {
    match,
    getPatientSessionsModel,
    setPatientSessionsModel,
    patientSessionsModel,
    updatePatientSessionsModel,
    getAnswersCopyPatientSessionsModel,
    getPrevPatientSessionsModel,
  } = props;
  const patientSessionId = match.params.id;
  const { data: questionnaire, loading, errors } = patientSessionsModel;
  const questionnaireId = questionnaire?.textId;
  const [patientAnswers, setPatientAnswers] = useState<IPatientAnswer[]>([]);
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    getPatientSessionsModel(patientSessionId);
  }, []);

  useEffect(() => {
    if (!questionnaire) {
      return;
    }

    const patientAnswers: IPatientAnswer[] = [];

    questionnaire.elements.forEach((field: IQuestionnaireElement) => {
      if (field.patientAnswers === undefined) {
        return;
      }

      // Handle answers for SelectGroup component
      if (field.patientAnswers instanceof Array && answerIsObject(field.patientAnswers[0])) {
        field.patientAnswers.forEach((answer: ISelectGroupAnswer) => {
          answer && patientAnswers.push({ name: [field.textId, ...Object.keys(answer)], value: Object.values(answer).join() });
        });
      } else {
        patientAnswers.push({
          name: field.textId,
          value: field.type === 'datePicker' ? moment(field.patientAnswers) : field.patientAnswers,
        });
      }
    });

    setPatientAnswers(patientAnswers);
  }, [questionnaire]);

  useEffect(() => {
    if (!loading && !errors) {
      setSuccess(true);

      const timeout = setTimeout(() => setSuccess(false), STATUS_BAR_HIDE_DELAY);

      return () => clearTimeout(timeout);
    }

    // eslint-disable-next-line sonarjs/no-redundant-jump
    return;
  }, [loading]);

  const filterEmptyComments = (comments: IQuestionnaireComment[]) => {
    return comments?.filter((comment: IQuestionnaireComment) => comment.comment.length) || [];
  };

  const submitData = (params: IBaseParams, update?: boolean) => {
    if (!questionnaireId) {
      return;
    }

    const comments: IQuestionnaireComment[] | [] = params?.comments ? filterEmptyComments(params.comments) : [];
    const data = {
      patientSessionId,
      questionnaireId,
      elements: params.elements,
      comments,
    };

    update ? updatePatientSessionsModel(data) : setPatientSessionsModel(data);
  };

  return (
    <Layout className="layout">
      <Spin spinning={loading || !questionnaire?._id}>
        {questionnaire &&
          getQuestionnaire(
            questionnaire,
            submitData,
            getAnswersCopyPatientSessionsModel,
            { loading, success, errors: errors || false },
            patientAnswers,
            patientSessionId,
            getPrevPatientSessionsModel
          )}
      </Spin>
    </Layout>
  );
};

export const Switcher = withRouter(communicationPatientSessions.injector(SwitcherComponent));
