import { IFormValues, IQuestionnaireAnswer, IValueChangeType } from 'common/models/formBuilder.models';
import { IQuestionnaireElement } from 'entities/PatientSessions/PatientSession.models';

export const tableFormBuilderMapper = (
  questionnaireId: string,
  element: IQuestionnaireElement | undefined,
  value: IFormValues,
  question: string,
  type?: IValueChangeType
): IQuestionnaireAnswer => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (questionnaireId) {
    case 'baseline_medications_list':
      // Remove intermediate field from answers object
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      value[1] && delete value[1][`${value[0]}_question` as keyof typeof value[1]];
      return {
        questionId: value[0],
        answers: value[1],
        question: question,
        ...(element?.isUserDefined && { isUserDefined: true }),
        ...((type?.deleted === value[0] || element?.isUserDeleted) && { isUserDeleted: true }),
        ...(type?.returned === value[0] && { isUserDeleted: false }),
      };
    default:
      return {
        questionId: value[0],
        answers: value[1],
        question: question,
        ...(element?.isUserDefined && { isUserDefined: true }),
        ...(element?.isUserDeleted && { isUserDeleted: true }),
      };
  }
};
