import { patientSessionsUploadImage } from 'entities/PatientSessions/PatientSession.transport';

export const uploadImage = async (
  studyPatientId: number,
  sessionId: string,
  dataURL: string,
  projection: string
): Promise<string> => {
  return await fetch(dataURL)
    .then((res) => res.blob())
    .then(async (blob) => {
      const file = new File([blob], `${projection}.jpg`, { type: 'image/jpeg' });
      const formData = new FormData();
      formData.append('image', file);

      return await patientSessionsUploadImage(studyPatientId, sessionId, formData).then((response) => response.path);
    });
};
