import React, { useState } from 'react';
import { Form, Table } from 'antd';
import { FormSelect } from 'common/components/Form/FormSelect';
import { IBaseParams, IFormValues, IQuestionnaireAnswer, IQuestionnaireData } from 'common/models/formBuilder.models';
import { ManageBtns } from 'common/components/Form/ManageBtns';
import { IQuestionnaireElement, IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  handleSubmit: (params: IBaseParams) => void;
}

export const DiaryOptionsPage: React.FC<IComponentProps> = (props) => {
  const { questionnaire, handleSubmit } = props;

  // Slice pain areas data
  const painAreasSlice = questionnaire.elements.slice(1, questionnaire.elements.length);
  const data: IQuestionnaireData[] = painAreasSlice.map((element: IQuestionnaireElement, index: number) => ({
    key: index,
    pain_area: element.question,
    item: element.textId,
  }));
  const [comment, setComment] = useState<string>('');

  const columns = [
    {
      title: 'Pain Areas',
      dataIndex: 'pain_area',
    },
    {
      title: 'Track',
      render: (key: IQuestionnaireData) => (
        <FormSelect
          key={key.key}
          name={key.item}
          options={painAreasSlice[key.key].answers}
          initialValue={painAreasSlice[key.key].patientAnswers}
        />
      ),
    },
  ];

  const handleSave = (comment: string) => {
    setComment(comment);
  };

  const onSubmit = (values: IFormValues) => {
    const answers: IQuestionnaireAnswer[] = [];
    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        answers.push({
          questionId: key,
          question: questionnaire.elements.find((element: IQuestionnaireElement) => element.textId === key)?.question,
          answers: value,
        });
      }
    });
    handleSubmit({ elements: answers, comments: [{ textId: questionnaire.textId, comment: comment }] });
  };

  return (
    <Form layout="vertical" onFinish={onSubmit}>
      <h1 className="form__title">{questionnaire.name}</h1>
      <p className="form__description">
        Every day you will receive a link to enter your daily pain levels, medications, symptoms and treatment summary.
        <br /> Each morning you will be reminded if you haven&apos;t completed the previous day&apos;s entries. <br />
        Please keep up every day, or let us know if you&apos;re having any difficulty. We&apos;re happy to help, including if you
        have internet
        <br /> issues, offline vacation or any other challenges. In these instances, we can provide paper forms for a short time
        to
        <br /> ensure accurate data collection every day.;
      </p>
      <div className="diary-options-page__communication">
        <FormSelect
          name={questionnaire.elements[0].textId}
          label={questionnaire.elements[0].question}
          options={questionnaire.elements[0].answers}
          initialValue={questionnaire.elements[0].patientAnswers}
        />
      </div>
      <p className="form__subtitle">Body parts for tracking</p>
      <p className="form__description">Please specify the body parts you want to keep tracking of in your daily diary</p>
      <Table columns={columns} dataSource={data} pagination={false} />
      <ManageBtns handleSave={handleSave} />
    </Form>
  );
};
