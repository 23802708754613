import React from 'react';
import { FormInstance } from 'antd/lib/form/Form';
import { IFormValues, IPatientAnswer, IQuestionnaireData, IValueChangeType } from 'common/models/formBuilder.models';
import { PainQuestionsPage } from 'app/pages/PainQuestionsPage';
import { BedtimePainQuestions } from 'app/pages/BedtimePainQuestionsPage';
import { SymptomsListPage } from 'app/pages/SymptomsListPage';
import { MedicationsListPage } from 'app/pages/MedicationsListPage';
import { PretherapyTPLELT } from 'app/pages/PretherapyTPLELT';
import { PretherapySymptompsList } from 'app/pages/PretherapySymptompsList';
import { IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

export const getTableQuestionnaire = (
  questionnaire: IQuestionnaireModel,
  questionnaireId: string,
  name: string,
  data: IQuestionnaireData[],
  onSubmit: () => void,
  handleSave: (comment: string) => void,
  handleChange: (values: IFormValues, formController: FormInstance<IFormValues>, type?: IValueChangeType) => void,
  patientAnswers?: IPatientAnswer[]
): JSX.Element => {
  switch (questionnaireId) {
    case 'baseline_pain_questions':
      return (
        <PainQuestionsPage
          questionnaire={questionnaire}
          name={name}
          data={data}
          onSubmit={onSubmit}
          handleSave={handleSave}
          handleChange={handleChange}
          patientAnswers={patientAnswers}
        />
      );
    case 'baseline_bed_time':
      return (
        <BedtimePainQuestions
          questionnaire={questionnaire}
          name={name}
          data={data}
          onSubmit={onSubmit}
          handleSave={handleSave}
          patientAnswers={patientAnswers}
        />
      );
    case 'baseline_symptoms_list':
      return (
        <SymptomsListPage
          questionnaire={questionnaire}
          name={name}
          data={data}
          onSubmit={onSubmit}
          handleSave={handleSave}
          patientAnswers={patientAnswers}
        />
      );
    case 'baseline_medications_list':
      return (
        <MedicationsListPage
          questionnaire={questionnaire}
          name={name}
          data={data}
          onSubmit={onSubmit}
          handleSave={handleSave}
          handleChange={handleChange}
          patientAnswers={patientAnswers}
        />
      );
    case 'pretherapy_typical_pain_levels_expected_later_today':
      return (
        <PretherapyTPLELT
          questionnaire={questionnaire}
          name={name}
          data={data}
          onSubmit={onSubmit}
          handleSave={handleSave}
          handleChange={handleChange}
          patientAnswers={patientAnswers}
        />
      );
    case 'pretherapy_symptoms_list':
      return (
        <PretherapySymptompsList
          questionnaire={questionnaire}
          name={name}
          data={data}
          onSubmit={onSubmit}
          handleSave={handleSave}
          handleChange={handleChange}
          patientAnswers={patientAnswers}
        />
      );
    default:
      return <div></div>;
  }
};
