import React from 'react';
import { Form, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import { baseValidationRules } from 'common/const/questionnaire.const';

const { Option } = Select;

interface IComponentProps {
  label?: string;
  className?: string;
  options: string[];
  mode?: 'multiple' | 'tags';
  allowClear?: boolean;
  name?: string | string[];
  initialValue?: string;
  placeholder?: string;
  rules?: Rule[];
  disabled?: boolean;
  preserve?: boolean;
}

export const FormSelect: React.FC<IComponentProps> = (props) => {
  const { label, className, options, mode, allowClear, name, initialValue, placeholder, rules, disabled, preserve } = props;

  return (
    <Form.Item
      className={className}
      label={label}
      name={name}
      initialValue={initialValue}
      rules={!disabled ? rules || baseValidationRules() : undefined}
      preserve={preserve}
    >
      <Select mode={mode} allowClear={allowClear} placeholder={placeholder} disabled={disabled}>
        {options?.map((item, index) => {
          return (
            <Option key={index} value={item}>
              {item}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};
