import React from 'react';
import { Button } from 'antd';
import { IBaseParams } from 'common/models/formBuilder.models';

interface IComponentProps {
  handleSubmit: (params: IBaseParams) => void;
}

export const PretherapyStartPage: React.FC<IComponentProps> = (props) => {
  const { handleSubmit } = props;

  const onSubmit = () => {
    handleSubmit({ elements: [] });
  };

  return (
    <div className="pretherapy-text-page__container">
      <p className="form__title pretherapy-text-page__title">
        Please schedule a time with your coordinator for your first treatment.
        <br /> The first treatment may take several hours and should start with pain of at least 4 out of 10.
      </p>
      <Button className="pretherapy-text-page__btn" type="primary" onClick={onSubmit}>
        Procced
      </Button>
    </div>
  );
};
