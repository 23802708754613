import { ECommonBodyParts } from 'common/const/bodyAreas.const';
import { IBodyParts } from 'common/models/painAreas.models';
import {
  EChestAbdomenBack,
  EFeet,
  EHeadNeck,
  ELeftArm,
  ELeftHand,
  ELegs,
  ERightArm,
  ERightHand,
} from 'common/const/painAreas/bodyParts.enums';
import { heatmapAreas } from 'common/const/painAreas/painAreasHeatmaps.const';
import headNeckFront from 'app/assets/images/painAreas/female/headNeckFront.png';
import headNeckFrontHeatmap from 'app/assets/images/painAreas/female/heatmaps/headNeckFront.heatmap.png';
import headNeckBack from 'app/assets/images/painAreas/female/headNeckBack.png';
import headNeckBackHeatmap from 'app/assets/images/painAreas/female/heatmaps/headNeckBack.heatmap.png';
import headNeckLeft from 'app/assets/images/painAreas/female/headNeckLeft.png';
import headNeckLeftHeatmap from 'app/assets/images/painAreas/female/heatmaps/headNeckLeft.heatmap.png';
import headNeckRight from 'app/assets/images/painAreas/female/headNeckRight.png';
import headNeckRightHeatmap from 'app/assets/images/painAreas/female/heatmaps/headNeckRight.heatmap.png';
import headNeckTop from 'app/assets/images/painAreas/female/headNeckTop.png';
import headNeckTopHeatmap from 'app/assets/images/painAreas/female/heatmaps/headNeckTop.heatmap.png';
import rightArmFront from 'app/assets/images/painAreas/female/rightArmFront.png';
import rightArmFrontHeatmap from 'app/assets/images/painAreas/female/heatmaps/rightArmFront.heatmap.png';
import rightArmBack from 'app/assets/images/painAreas/female/rightArmBack.png';
import rightArmBackHeatmap from 'app/assets/images/painAreas/female/heatmaps/rightArmBack.heatmap.png';
import leftArmFront from 'app/assets/images/painAreas/female/leftArmFront.png';
import leftArmFrontHeatmap from 'app/assets/images/painAreas/female/heatmaps/leftArmFront.heatmap.png';
import leftArmBack from 'app/assets/images/painAreas/female/leftArmBack.png';
import leftArmBackHeatmap from 'app/assets/images/painAreas/female/heatmaps/leftArmBack.heatmap.png';
import rightHandFront from 'app/assets/images/painAreas/female/rightHandFront.png';
import rightHandFrontHeatmap from 'app/assets/images/painAreas/female/heatmaps/rightHandFront.heatmap.png';
import rightHandBack from 'app/assets/images/painAreas/female/rightHandBack.png';
import rightHandBackHeatmap from 'app/assets/images/painAreas/female/heatmaps/rightHandBack.heatmap.png';
import leftHandFront from 'app/assets/images/painAreas/female/leftHandFront.png';
import leftHandFrontHeatmap from 'app/assets/images/painAreas/female/heatmaps/leftHandFront.heatmap.png';
import leftHandBack from 'app/assets/images/painAreas/female/leftHandBack.png';
import leftHandBackHeatmap from 'app/assets/images/painAreas/female/heatmaps/leftHandBack.heatmap.png';
import chestAbdomen from 'app/assets/images/painAreas/female/chestAbdomen.png';
import chestAbdomenHeatmap from 'app/assets/images/painAreas/female/heatmaps/chestAbdomen.heatmap.png';
import back from 'app/assets/images/painAreas/female/back.png';
import backHeatmap from 'app/assets/images/painAreas/female/heatmaps/back.heatmap.png';
import right from 'app/assets/images/painAreas/female/right.png';
import rightHeatmap from 'app/assets/images/painAreas/female/heatmaps/right.heatmap.png';
import left from 'app/assets/images/painAreas/female/left.png';
import leftHeatmap from 'app/assets/images/painAreas/female/heatmaps/left.heatmap.png';
import legsFront from 'app/assets/images/painAreas/female/legsFront.png';
import legsFrontHeatmap from 'app/assets/images/painAreas/female/heatmaps/legsFront.heatmap.png';
import legsFrontGenitals from 'app/assets/images/painAreas/female/legsFrontGenitals.png';
import legsBack from 'app/assets/images/painAreas/female/legsBack.png';
import legsBackHeatmap from 'app/assets/images/painAreas/female/heatmaps/legsBack.heatmap.png';
import leftLeg from 'app/assets/images/painAreas/female/leftLeg.png';
import leftLegHeatmap from 'app/assets/images/painAreas/female/heatmaps/leftLeg.heatmap.png';
import rightLeg from 'app/assets/images/painAreas/female/rightLeg.png';
import rightLegHeatmap from 'app/assets/images/painAreas/female/heatmaps/rightLeg.heatmap.png';
import feetFront from 'app/assets/images/painAreas/female/feetFront.png';
import feetFrontHeatmap from 'app/assets/images/painAreas/female/heatmaps/feetFront.heatmap.png';
import feetBack from 'app/assets/images/painAreas/female/feetBack.png';
import feetBackHeatmap from 'app/assets/images/painAreas/female/heatmaps/feetBack.heatmap.png';

export const femaleAreas: IBodyParts = {
  [ECommonBodyParts.HeadNeck]: [
    {
      name: EHeadNeck['Head/Neck (Front)'],
      image: headNeckFront,
      heatmap: headNeckFrontHeatmap,
      heatmapData: heatmapAreas[EHeadNeck['Head/Neck (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: EHeadNeck['Head/Neck (Back)'],
      image: headNeckBack,
      heatmap: headNeckBackHeatmap,
      heatmapData: heatmapAreas[EHeadNeck['Head/Neck (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: EHeadNeck['Head/Neck (Right)'],
      image: headNeckRight,
      heatmap: headNeckRightHeatmap,
      heatmapData: heatmapAreas[EHeadNeck['Head/Neck (Right)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: EHeadNeck['Head/Neck (Left)'],
      image: headNeckLeft,
      heatmap: headNeckLeftHeatmap,
      heatmapData: heatmapAreas[EHeadNeck['Head/Neck (Left)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: EHeadNeck['Head/Neck (Top)'],
      image: headNeckTop,
      heatmap: headNeckTopHeatmap,
      heatmapData: heatmapAreas[EHeadNeck['Head/Neck (Top)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.RightArm]: [
    {
      name: ERightArm['Right Arm (Front)'],
      image: rightArmFront,
      heatmap: rightArmFrontHeatmap,
      heatmapData: heatmapAreas[ERightArm['Right Arm (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ERightArm['Right Arm (Back)'],
      image: rightArmBack,
      heatmap: rightArmBackHeatmap,
      heatmapData: heatmapAreas[ERightArm['Right Arm (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.LeftArm]: [
    {
      name: ELeftArm['Left Arm (Front)'],
      image: leftArmFront,
      heatmap: leftArmFrontHeatmap,
      heatmapData: heatmapAreas[ELeftArm['Left Arm (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ELeftArm['Left Arm (Back)'],
      image: leftArmBack,
      heatmap: leftArmBackHeatmap,
      heatmapData: heatmapAreas[ELeftArm['Left Arm (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.RightHand]: [
    {
      name: ERightHand['Right Hand (Front)'],
      image: rightHandFront,
      heatmap: rightHandFrontHeatmap,
      heatmapData: heatmapAreas[ERightHand['Right Hand (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ERightHand['Right Hand (Back)'],
      image: rightHandBack,
      heatmap: rightHandBackHeatmap,
      heatmapData: heatmapAreas[ERightHand['Right Hand (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.LeftHand]: [
    {
      name: ELeftHand['Left Hand (Front)'],
      image: leftHandFront,
      heatmap: leftHandFrontHeatmap,
      heatmapData: heatmapAreas[ELeftHand['Left Hand (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ELeftHand['Left Hand (Back)'],
      image: leftHandBack,
      heatmap: leftHandBackHeatmap,
      heatmapData: heatmapAreas[ELeftHand['Left Hand (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.ChestAbdomenBack]: [
    {
      name: EChestAbdomenBack['Chest/Abdomen'],
      image: chestAbdomen,
      heatmap: chestAbdomenHeatmap,
      heatmapData: heatmapAreas[EChestAbdomenBack['Chest/Abdomen']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: EChestAbdomenBack['Back'],
      image: back,
      heatmap: backHeatmap,
      heatmapData: heatmapAreas[EChestAbdomenBack['Back']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: EChestAbdomenBack['Left'],
      image: left,
      heatmap: leftHeatmap,
      heatmapData: heatmapAreas[EChestAbdomenBack['Left']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: EChestAbdomenBack['Right'],
      image: right,
      heatmap: rightHeatmap,
      heatmapData: heatmapAreas[EChestAbdomenBack['Right']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.Legs]: [
    {
      name: ELegs['Legs (Front)'],
      image: legsFront,
      imageGenitals: legsFrontGenitals,
      heatmap: legsFrontHeatmap,
      heatmapData: heatmapAreas[ELegs['Legs (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ELegs['Legs (Back)'],
      image: legsBack,
      heatmap: legsBackHeatmap,
      heatmapData: heatmapAreas[ELegs['Legs (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ELegs['Left Leg'],
      image: leftLeg,
      heatmap: leftLegHeatmap,
      heatmapData: heatmapAreas[ELegs['Left Leg']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ELegs['Right Leg'],
      image: rightLeg,
      heatmap: rightLegHeatmap,
      heatmapData: heatmapAreas[ELegs['Right Leg']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.Feet]: [
    {
      name: EFeet['Feet (Front)'],
      image: feetFront,
      heatmap: feetFrontHeatmap,
      heatmapData: heatmapAreas[EFeet['Feet (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: EFeet['Feet (Back)'],
      image: feetBack,
      heatmap: feetBackHeatmap,
      heatmapData: heatmapAreas[EFeet['Feet (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
};
