import React from 'react';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { history } from 'app/store/createStore';
import { Switcher } from 'app/pages/Switcher';
import '@axmit/tmq-api';

interface IComponentProps {
  store: Store;
}

type AllProps = IComponentProps;

const App: React.FC<AllProps> = (props) => {
  const { store } = props;

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/:id" component={Switcher} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
